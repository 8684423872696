import React, { useState, useEffect, useRef } from "react"
import {QuestionsAnswers } from "./styles.js"

const Questions = ({question, answer, myRef}) => {
    const [isAnswerOppened, setIsAnswerOppened] = useState(true)

    function seeAnswer(e) {
        setIsAnswerOppened(!isAnswerOppened)
    }

    return (
        <QuestionsAnswers>
            <div className="question"><div className="text">{question}</div> <button onClick={() => setIsAnswerOppened(!isAnswerOppened)}><div className="arrow" style={{ transform: isAnswerOppened ? `rotate(180deg)` : `rotate(360deg)` }} /></button></div>
            <div className="answer" style={{ padding: !isAnswerOppened ? `25px 0px 20px` : `0px` }}>{answer}</div>
        </QuestionsAnswers>
    )
}

export default Questions