import React, { useState, useEffect } from "react"
import { Container, Logo } from "./styles.js"
import { NavLink, Link } from 'react-router-dom'
import logo from '../../images/logo.png'
import BurgerBtn from '../buttons/burger.js'

const Header = ({isMenuOpen, setIsMenuOpen}) => {
  return (
    <Container>
      <Link to='/'><Logo><span>For</span><img src={logo} alt='m' /><span>ika</span></Logo></Link>
      <nav>
        <NavLink to='/' exact>Главная</NavLink>
        <NavLink to='/about'>Услуги</NavLink>
        <NavLink to='/legalization'>Легализация</NavLink>
        <NavLink to='/vacancies' activeClassName="active">Вакансии</NavLink>
        <NavLink to='/blog' activeClassName="active">Блог</NavLink>
        <NavLink to='/feedbacks' activeClassName="active">Отзывы</NavLink>
      </nav>
      <BurgerBtn isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </Container>
  )
}

export default Header