import styled from "styled-components"
import bg from '../../images/vacancy.webp'
import media from 'styled-media-query'

export const Container = styled.div`
   background: rgba(0,0,0,0.05);
`
export const Intro = styled.div`
    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 -170px;
    height: 60vh;
    color: #fff;
    position: relative;
    
    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
    }

    .intro_content{
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: -webkit-fill-available;
    }

    h2{
        font-family: 'Roboto Condensed', sans-serif; 
        font-weight: 600;
        letter-spacing: 0.8px;
        font-size: 48px;
        margin-bottom: 12px;
        padding-left: 70px;
    }

    p{
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 24px; 
        padding: 0 0 50px 70px;
        border-bottom: 5px solid rgba(251,175,67,0.5);
        width: 65%;
    }

    ${media.lessThan('768px')`
        background-position: center;
        h2, p{
            padding-left: 30px;
        }
        h2{
            font-size: 36px;
        }
        .intro_content{
            padding-top: 45px;
        }
    `}
`
export const VacanciesWrapper = styled.div`
    width: 85%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    padding: 100px 0 100px;

    ${media.lessThan('1100px')`
        grid-template-columns: 1fr;
        padding: 90px 0 80px;
    `}
`
export const RequestCall = styled.div`
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    p{
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 24px;
        text-align: center;

        ${media.lessThan('768px')`
            font-size: 20px;
            margin: 0 10px;
        `}
    }
    button{
       margin-top: 25px;
    }
`
export const Vacancy = styled.div`
    display: flex;
    background: #fff;
    padding: 12px;
    height: 88%;
    position: relative;

    .img{
        width: 45%;
        position: relative;

        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        ${media.lessThan('620px')`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        `}
    }

    .title{
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 100;
        color: #FBAF43;
        font-size: 20px;
        margin-bottom: 5px;
    }

    p{
        margin-bottom: 15px;
        font-family: 'Roboto Condensed', sans-serif;
        color: rgba(0,0,0,0.7);
        font-size: 14px;
        display: flex;
        align-items: center;

        span{
            width: 30px;
            margin-right: 5px;
            display: block;
            height: 1px;
            background: #FBAF43;
        }
    }

    .icon{
        width: 20px;
        vertical-align: bottom;
        margin-right: 7px;
    }

    .info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 15px;
        width: 55%;

        ${media.lessThan('620px')`
            width: 100%;
        `}
    }

    .info_item{
        margin-bottom: 5px;
        font-family: 'Roboto Condensed', sans-serif;
        color: rgba(0,0,0,0.8);
        font-size: 18px;
        display: flex;
        align-items: center;
    }

    button{
        border-radius: 5px;
        background: #FBAF43;
        // width: 45px;
        // height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-top: 10px;
        color: #fff;
        padding: 10px 15px;

        img{
            width: 25px;
            transition: all 0.3s ease-out;
        }

        :hover{
            img{
                transform: scale(1.2);
            }
        }
    }

    .irrelevant_vacancy{
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0,0,0,0.7);
        color: white;
        font-size: 26px;
        font-family: 'Roboto Condensed', sans-serif;
        text-align: center;
        
        ${media.lessThan('620px')`
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        `}
    }

    ${media.lessThan('620px')`
        .main_img{
            display:none;
        }
    `}
`
export const Form = styled.div`

`