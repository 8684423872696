import React, { useState, useCallback } from 'react'
import { HouseGallery, Modal } from './styles.js'
import ImageViewer from 'react-simple-image-viewer'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import disableScroll from 'disable-scroll'

const Gallery = ({ photos }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const images = photos

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index)
        setIsViewerOpen(true)
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0)
        setIsViewerOpen(false)
    }

    const modalWindowClicked = () => {
        disableScroll.off()
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
        ]
    };

    return (
        <HouseGallery>
            {
                images == null ? null :
                    <Slider {...settings}>
                        {
                            images.map((src, index) => (
                                <img
                                    src={src}
                                    onClick={() => openImageViewer(index)}
                                    key={index}
                                    alt=""
                                />
                            ))
                        }
                    </Slider>
            }

            {isViewerOpen && (
                <Modal>
                    <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        disableScroll={true}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                        onClick={modalWindowClicked}
                    >
                    </ImageViewer> 
                    <button onClick={closeImageViewer}/>
                </Modal>
            )}
        </HouseGallery>
    );
}

export default Gallery