import React from "react"
import { PostsContainer, PostsIntro, InfoBlock, Posts } from "./styles.js"
import { Link } from 'react-router-dom'
import { blogData } from "./data.js"
import Slide from 'react-reveal/Slide'

const Blog = () => {
  return (
    <PostsContainer>
      <Slide top>
        <PostsIntro>
          <h2>Все, что Вам нужно знать о работе в Польше!</h2>
        </PostsIntro>
      </Slide>
      <Posts>
        {blogData.map((data, key) => {
          let link = "/article/" + data.id
          return (
            <InfoBlock>
              <div className='img_block'>
                <img src={data.img} alt='img' />
                <span className='date'>{data.date}</span>
              </div>
              <div className='info'>
                <h3>{data.name}</h3>
                <p>{data.desc}</p>
                <Link to={link}><button>Подробнее <span className='arrow' /> </button></Link>
              </div>
            </InfoBlock>
          )
        })}
      </Posts>
    </PostsContainer>
  )
}

export default Blog