import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.header`
    background: #000;
    display: flex;
    justify-content: space-between;
    padding: 20px 35px 20px 20px;
    position: fixed;
    top: 0;
    z-index: 5;
    width: 100vw;
    color: #fff;
    
    nav {
        margin-right: 90px;
        
        a{
            color: #fff;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.15em;
            text-transform: uppercase;
            margin-right: 20px;
            transition: all .3s linear;
            position: relative;

            :last-child{
                margin-right: 0;
            }

            :after{
                content: "";
                position: absolute;
                left: 0;
                bottom: -2px;
                height: 1px;
                width: 100%;
                background: transparent;
                transition: all .3s linear;
            }
    
            :hover{
                :after{
                    background: #fff;
                }
            }
        }

        ${media.lessThan('768px')`
            display: none;
        `} 
    }

    ${media.lessThan('768px')`
        padding: 20px 30px 20px 30px;
    `} 
`
export const Logo = styled.div`
    font-size: 26px;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    display: flex;
    align-items: center;

    img{
        max-height: 35px;
        padding: 0px 2px 0px 6px;
        vertical-align: -3px;
        background: black;
        transition: all 1s ease-out;
    }

    span:first-child{
        transform: translateX(100%);
        display: block;
        position: relative;
        z-index: -1;
        transition: all 1s ease-out;
        margin-right: 1px;
    }
    span:last-child{
        transform: translateX(-101%);
        display: block;
        position: relative;
        z-index: -1;
        transition: all 1s ease-out;
    }

    :hover{
        img{
            max-height: 25px;
        }
        span:first-child{
            transform: translateX(0);
        } 
        span:last-child{
            transform: translateX(0);
        } 
    }

    ${media.lessThan('1024px')`
        span{
            display: none !important;
        } 

        :hover{
            img{
                max-height: 35px;
            }  
        }

        img{
            margin-left: 20px;
        }
    `}

    ${media.lessThan('768px')`
        img{
            padding: 0;
            margin-left: 0;
        }
    `}
`