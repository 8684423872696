import React, { useRef } from "react"
import Intro from '../intro/intro.js'
import About from '../about/about.js'
import Blog from '../blog/blogIntro.js'
import Partners from '../partnersLogo/partnersLogo.js'
import FeedBacks from "../feedbacks/feedbacks.js"
import Why from "../why/why.js"

const MainPage = ({openModal}) => {
  const myRef = useRef(null)

  function executeScroll(ref) {
    window.scroll({
      top: ref.current.offsetTop,
      behavior: "smooth",
    })
  }

  return (
    <>
      <Intro executeScroll={executeScroll} myRef={myRef} />
      <About myRef={myRef} openModal={openModal}/>
      <Why /> 
      <Partners />
      <FeedBacks />
      <Blog />
    </>
  )
}

export default MainPage