import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyDVinLUabQP9boSdRcV3VZ8PTjugd4ctNk",
    authDomain: "formika-6b864.firebaseapp.com",
    databaseURL: "https://formika-6b864-default-rtdb.firebaseio.com",
    projectId: "formika-6b864",
    storageBucket: "formika-6b864.appspot.com",
    messagingSenderId: "761026152336",
    appId: "1:761026152336:web:414ad163213bda630b9515",
    measurementId: "G-CMPHVQLD59"
};

firebase.initializeApp(config);

export default firebase;