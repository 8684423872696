import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
    padding: 70px 0 120px;
    background: #FFFBEF;
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 22px;
    position: relative;

    ${media.lessThan('768px')`
        padding: 40px 0 150px;
    `}

    :after{
        position: absolute;
        content: "";
        width: 100%;
        height: 40%;
        background: #141414;
        bottom: 50px;
        z-index: 0;
    }

    h2{
        margin: 0 auto 70px;

        ${media.lessThan('768px')`
            margin: 0 auto 70px;
        `}
    }

    .feedback_text{
        margin-bottom: 25px;
        color: rgba(0,0,0,0.8);
    }

    .name{
        font-weight: 600;
        text-align: end;
    }

    .position{
        color: rgba(0,0,0,0.6);
        text-align: end;
        margin-bottom: 30px;
    }

    .button{
        color: #fff;
        position: absolute;
        font-size: 20px;
        font-family: 'Roboto Condensed', sans-serif;
        z-index: 2;
        left: 13%;

        .arrow{
            height: 2px;
            width: 22px;
            background: #fff;
            display: inline-block;
            vertical-align: middle;
            margin-left: 7px;
            position: relative;

            :after, :before{
                content: '';
                position: absolute;
                width: 11px;
                height: 2px;
                background: #fff;
            }
            :after{
                transform: rotate(45deg);
                right: -3px;
                top: -3.5px;
            }
            :before{
                transform: rotate(-45deg);
                right: -3px;
                bottom: -3.5px;
            }
        }

        ${media.lessThan('768px')`
            left: 50% !important;
            transform: translate(-50%, 40px);
        `} 
    }

    .slick-list{
        z-index: 1;
        width: 80%;
        margin: 0 auto;
        padding-bottom: 90px;
    }

    .slide{
        background: #fff;
        width: 80% !important;
        border: 1px solid #ECEFF5;
        padding: 57px 25px 0;
        -webkit-box-shadow: 0 0 15px 0 rgb(51 77 136 / 10%);
        box-shadow: 0 0 15px 0 rgb(51 77 136 / 10%);
        border-radius: 3px;
        position: relative;
        height: 100%;
        display: flex !important;
        flex-direction: column;
        justify-content: space-between;
    }

    .slick-slide{
        height: 250px !important;
    }

    .slick-dots li button:before{
        color: #FBAF43;
        background: #FBAF43;
        position: relative;
        z-index: 1;
        border-radius: 50%;
        font-size: 14px;
    }

    .slick-slide > div{
        display: flex;
        justify-content: center;
        height: 100%;
    }

    .quotes{
        position: absolute;
        width: 30px;
        top: 14px;
        left: 16px;
    }

    .slick-arrow{
        display: none !important;
    }

    ${media.lessThan('1024px')`
        .slick-list{
            width: 90%;
        }
    `}
`
export const FeedbacksContainer = styled.div`
    padding: 150px;
    font-family: 'Roboto Condensed', sans-serif;

    .flex{
        display: flex;
        justify-content: space-between;

        ${media.lessThan('768px')`
            flex-direction: column;
        `}
    } 
    
    ${media.lessThan('768px')`
        padding: 110px 30px 70px;
    `}
`
export const FeedbackButton = styled.button`
    font-family: 'Roboto Condensed', sans-serif;
    color: rgba(0,0,0,0.8);
    font-size: 24px;
    margin: 0 auto 40px;
    display: block;

    .arrow{
        width: 2.5px;
        height: 21px;
        background: rgba(0,0,0,0.8);
        display: block;
        margin: 7px auto 0;
        position: relative;

        :after, :before{
            content: '';
            position: absolute;
            width: 2px;
            height: 14px;
            bottom: -3px;
            background: rgba(0,0,0,0.8);  
        }
        :after{
            right: 5px;
            transform: rotate(-45deg);
        }
        :before{
            left: 5px;
            transform: rotate(45deg);
        }
    }
`
export const FeedbackItems = styled.div`
    .feedback_item{
        border-bottom: 1px solid rgba(0,0,0,0.05);
        margin-bottom: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .name{
        font-size: 20px;
        color: #FBAF43;
    }
    .text{
        margin-bottom: 30px;
        width: 80%;
        line-height: 22px;
    }
    .date{
        color: rgba(0,0,0,0.2);
    }
`
export const Form = styled.div`
    width: 60%;
    margin: 0 auto;
    padding-top: 120px;

   .note{
        color: rgba(0,0,0,0.6);
        letter-spacing: 0.8px;
    }
    .textarea{
        width: 100%;
    }
    span{
        color: rgba(255, 0, 0, 0.8);
    }
    .hidden{
        height: 0px;
    }

    ${media.lessThan('768px')`
        width: 100%;
        padding-top: 80px;
    `}
`
export const Input = styled.div`
    display: flex;
    flex-direction: column;
    width: 49%;

    label{
        margin-bottom: 7px;
        color: rgba(0,0,0,0.8);
    }

    input, textarea{
        padding: 15px;
        border: 1px solid #FBAF43;
        margin-bottom: 17px;
        color: rgba(0,0,0,0.8);

        ::placeholder{
            font-family: 'Roboto Condensed', sans-serif;
            font-size: 16px;
        }
    }

    ${media.lessThan('768px')`
        width: 100%;
    `}
`
export const Stars = styled.div`
   font-size: 28px;
   margin-bottom: 10px;
   display: flex;

   .star{
        color: #ff9933;
        cursor: pointer;
    }

    ${media.lessThan('768px')`
        font-size: 24px;
    `}
`
export const Message = styled.div`
   font-size: 12px;
   margin-bottom: 30px;
   font-style: italic;
`
export const MessageMain = styled.div`
    margin-top: 100px;
    font-size: 38px;
    text-align: center;
    color: #ff9933;
    font-family: 'Roboto Condensed', sans-serif;
`