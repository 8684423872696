import React from "react"
import { Container, Intro, Services, ServicesItems, Number, Partners } from "./styles"
import { Button } from '../buttons/buttons.js'
import { SectionTitle } from '../titles/index.js'
import Slide from 'react-reveal/Slide'

const AboutPage = ({ myRef, openModal }) => {

    function executeScroll() {
        myRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <Container>
            <Intro>
                <Slide left><div className='img-block'></div> </Slide>
                <Slide right>
                    <div className='block'>
                        <div className='content'>
                            <h1 className='title'>Агентство по трудоустройству <br /><span>Formika</span></h1>
                            <p className='text'>Польское кадровое агентство FORMIKA работает на международном уровне и уже более 5 лет предоставляет свои услуги в сфере трудоустройства работников из Восточной Европы. Гарантируем абсолютно легальное трудоустройство в Польше и стабильную заработную плату. Мы берем на себя оформление приглашений от работодателей и других документов, за вами остается только выбор одной из предложенных вакансий.</p>
                        </div>
                    </div>
                </Slide>
            </Intro>
            <Services>
                <SectionTitle>Наши услуги</SectionTitle>
                <ServicesItems>
                    <div className='item'>
                        <h4 className='title'>Бесплатная консультация</h4>
                        <p className='text'>Отправте нам сообщение или закажите бесплатный звонок. Наш менеджер ответит на все интересующие Вас вопросы.</p>
                        <div className='buttons'>
                            <button onClick={executeScroll}>Отправить сообщение</button>
                            <button onClick={openModal}>Заказать звонок</button>
                        </div>
                    </div>
                    <Number>01</Number>
                    <Number>02</Number>
                    <div className='item'>
                        <h4 className='title'>Подбор вакансии</h4>
                        <p className='text'>
                            Ознакомтесь с актуальными вакансиями по <a href='https://formikapraca.eu/vacancies' target='_blank'>ссылке</a> или закажите обратный звонок и наш менеджер подберет вакансию специально для Вас
                        </p>
                        <button className='btn' onClick={openModal}>Заказать звонок</button>
                    </div>
                    <div className='item'>
                        <h4 className='title'>Подготовка документов</h4>
                        <ul className='list'>
                            <li><a href="https://formikapraca.eu/article/3" target='_blank'>Oświadczenie powierzenia pracy</a></li>
                            <li><a href="https://formikapraca.eu/article/4" target='_blank'>Номер PESEL</a></li>
                            <li><a href="https://formikapraca.eu/article/16" target='_blank'>Воеводское приглашение</a></li>
                            <li><a href="https://formikapraca.eu/article/15" target='_blank'>Карта побыта</a></li>
                        </ul>
                        <button className='btn_transparent' onClick={executeScroll}>Задать вопрос</button>
                    </div>
                    <Number>03</Number>
                    <Number>04</Number>
                    <div className='item'>
                        <h4 className='title'>Профессиональная переподготовка</h4>
                        <p className='text'>Если, например, Вас заинтересовала вакансия <a href="https://formikapraca.eu/vacancy/5">Водитель погрузчика</a>, но у Вас нет необходимых для этого прав UDT, не переживайте, мы организовываем специальные курсы после которых Вы получите права на управление погрузчиком. </p>
                        <button className='btn' onClick={executeScroll}>Задать вопрос</button>
                    </div>
                </ServicesItems>
            </Services>
        </Container>
    )
}

export default AboutPage