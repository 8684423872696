import React, { useState, useEffect, useRef } from "react"
import { Container, Intro, VacanciesWrapper, Vacancy, Form, RequestCall } from "./styles.js"
import cityIcon from '../../images/pin.png'
import coinsIcon from '../../images/coins.svg'
import personIcon from '../../images/person.png'
import showIcon from '../../images/eye.svg'
import { Link } from 'react-router-dom'
import { vacancyData } from "../../data.js"
import Slide from 'react-reveal/Slide'
import { Button } from "../buttons/buttons.js"

const Vacancies = ({ openModal }) => {
    return (
        <Container>
            <Slide top>
                <Intro>
                    <div className='intro_content'>
                        <h2>Ищете работу в Польше?</h2>
                        <p>Ознакомтесь с нашими вакансиями</p>
                    </div>
                </Intro>
            </Slide>
            <RequestCall>
                <p>Закажите обратный звонок и мы поможем подобрать Вам вакансию!</p>
                <Button onClick={openModal}>Заказать звонок</Button>
            </RequestCall>
            <VacanciesWrapper>
                {vacancyData.map((data, key) => {
                    let link = "/vacancy/" + data.id
                    return (
                        <Vacancy>
                            <div className='img'>
                                {data.status === "notActive" ? <div className="irrelevant_vacancy">Неактуально</div> : null}
                                {data.status === "temporaryNotActive" ? <div className="irrelevant_vacancy">Набор временно закрыт</div> : null}
                                <img src={data.img} alt="img" className='main_img' />
                            </div>
                            <div className='info'>
                                <div>
                                    <h3 className='title'>{data.name}</h3>
                                    <p><span /> {data.factory}</p>
                                    <div className='info_item'><img src={cityIcon} alt="img" className='icon' /> {data.city}</div>
                                    <div className='info_item'><img src={coinsIcon} alt="img" className='icon' /> {data.salary[0]}</div>
                                    <div className='info_item'><img src={personIcon} alt="img" className='icon' /> {data.who}</div>
                                </div>
                                {/* <Link to={link}><button><img src={showIcon} alt="img" /></button></Link> */}
                                <Link to={link}><button>Подробнее</button></Link>
                            </div>
                        </Vacancy>
                    )
                })}
            </VacanciesWrapper>
            <div>

            </div>
        </Container>
    )
}

export default Vacancies