import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({setIsMenuOpen}) {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    setIsMenuOpen(false)
    console.log(pathname)
  }, [pathname]);

  return null;
}