import styled from "styled-components"
import media from 'styled-media-query'

export const Container = styled.div`
    margin: 100px 0 0;

    h2{
        margin: 0 auto 30px;
    }

    .slick-slider{
        width: 90%;
        margin: 0 auto;
    }

    .slick-track{
        display: flex;
        align-items: center;
    }

    .slick-slide{
        width: 10% !important;

        div{
            display: flex;
            justify-content: center;
        }

        img{
            width: 85% !important;
            height: 100%;

            ${media.lessThan('920px')`
                width: 92% !important;
            `}
        }
    }

    .slick-prev, .slick-next{
        position: absolute;

        ::before{
            color: #FBAF43; 
            font-size: 32px;
        }

        ${media.lessThan('1024px')`
            display: none !important;
        `}
    }
    .slick-prev{
        left: -50px !important;
    }
    .slick-next{
        right: -30px !important;
    }
`