import styled from "styled-components"
import img from '../../images/about_img.webp'
import media from 'styled-media-query'

export const Container = styled.div`
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 24px;
`
export const Intro = styled.div`
    height: 100vh;
    background: #141414;
    display: flex;
    align-items: center;
    color: #fff;
    position: relative;

    .block, .img-block{
        width: 50%;
    }
    .img-block{
        height: 100%;
        background-image: url(${img});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: -170px 0;
    }
    .block{
        .content{
            padding-left: 80px;
        }
        
        .title{
            margin-bottom: 30px;
            font-size: 38px;
            line-height: 46px;

            span{
                color: rgba(251, 175, 67, 1);
                font-size: 48px;
            }
        }
        .text{
            width: 70%;
            opacity: .8;
            font-size: 18px;
        }
    }

    ${media.lessThan('1200px')`
        .block{
            .content{
                padding-left: 50px;
            }
        }
    `} 

    ${media.lessThan('768px')`
        background-position: center;

        .block, .img-block{
            width: 100%;
            z-index: 1;
        }
        .img-block{
            position: absolute;
            height: 100%;
            background-position: center;
            z-index: 0;
        }
        :after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
        }
        .block{
            .title{
                    font-size: 32px;
                    line-height: 38px;
                    span{
                        font-size: 34px;
                    }
                }
                .text{
                    width: 92%;
                }
                .content{
                    padding-left: 30px;
                }
        }  
    `}
`
export const Services = styled.div`
    margin: 120px 0 100px;
    
    h2{
        margin: 0 auto 50px;
    }

    .items{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 4em;
        width: 80%;
        margin: 100px auto 0;
        font-size: 17px;
        color: rgba(0,0,0,0.7);
        position: relative;
    }

    .item{
        width: 85%;
    }

    .number{
        font-family: 'Russo One', sans-serif;
        font-weight: 900;
        font-size: 50px;
        color: rgba(251, 175, 67, .4);
        margin-bottom: 40px;
        display: block;
        letter-spacing: 3px;
    }

    ${media.lessThan('1024px')`
        .items{
            grid-template-columns: 1fr 1fr;
        }
    `} 

    ${media.lessThan('768px')`
        margin: 70px 0;
        
        .items{
            grid-column-gap: 15px;
            grid-row-gap: 3em;
            width: 87%;
            margin: 60px auto 0;
            grid-template-columns: 1fr 1fr;
        }
        .item{
            width: 100%;
        }
    `} 

    ${media.lessThan('500px')`
        .items{
            grid-template-columns: 1fr;
            width: 80%;
        }
    `} 
`
export const Number = styled.div`
    font-family: 'Russo One', sans-serif;
    font-weight: 900;
    font-size: 90px;
    color: rgba(251, 175, 67, .4);
    display: block;
    letter-spacing: 5px;  
    display: flex;
    align-items: center;
    
    :nth-child(odd){
        justify-content: flex-end;
        margin-right: 80px;
    }
    :nth-child(even){
        margin-left: 80px;
    }

    ${media.lessThan('768px')`
        display: none;
    `}
`
export const ServicesItems = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 4em;
    width: 70%;
    margin: 100px auto 0;

    .item{
        background: rgba(251, 175, 67, .15);
        width: fit-content;
        padding: 35px;
        border-radius: 15px;
    }

    .title{
        font-size: 30px;
        color: rgba(0,0,0,0.8);
        margin-bottom: 15px;
        font-weight: 900;
        line-height: 30px;
    }

    .text{
       width: 80%;
       font-size: 18px;

       a, span{
            // font-weight: 700;
            position: relative;
            // color:  #FBAF43;
            letter-spacing: 0.6px;
            text-decoration: underline;
            transition: all 0.3s linear;

            :hover{
                color: #FBAF43;
                // transform: scale(1.1);
                // display: inline-block;
                // margin: 0 3px;
            }   
       }
    }

    .buttons{
        display: flex;
        margin-top: 30px;
        
        button{
            padding: 10px 15px;
            border: solid 1px #FBAF43;
            color: #FBAF43;
            font-size: 16px;
            font-weight: 600;
            transition: all 0.3s linear;

            :hover{
                background: #FBAF43;
                color: #fff;
            }

            :first-child{
                margin-right: 10px;
            }
        }
    }

    .btn{
        background: rgba(251, 175, 67, 1);
        padding: 10px 15px;
        color: #fff;
        font-size: 16px;
        margin-top: 25px;
         transition: all 0.3s linear;

            :hover{
                transform: scale(1.1);
            } 
    }

    .btn_transparent{
        padding: 10px 15px;
        border: solid 1px #FBAF43;
        color: #FBAF43;
        font-size: 16px;
        font-weight: 600;
        transition: all 0.3s linear;
        margin-top: 20px;

        :hover{
            background: #FBAF43;
            color: #fff;
        }
    }

    .list{
        list-style: none;
        padding-left: 20px;
        font-size: 18px;
        margin-top: 25px;
        line-height: 28px;

        li{
            position: relative;
            transition: all 0.3s linear;
            
            :before{
                content: " ";
                position: absolute;
                width: 5px;
                height: 5px;
                border: 2px solid #FBAF43; 
                border-radius: 50%;
                left: -20px;
                top: 30%;
            }
            {
                :hover{
                    color: #FBAF43;
                }  
            }
        }
        
    }

    ${media.lessThan('1024px')`
        width: 90%;
    `}

    ${media.lessThan('768px')`
        width: 100%;
        grid-template-columns: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 3em;

        .item{
            padding: 20px;
            margin: 0 30px;
            width: auto;
        }

        .text{
            width: 100%;
        }
        .title{
            font-size: 26px;
        }
        .buttons{
            flex-direction: column;

            button{
                width: 100%;

                :first-child{
                    margin: 0 0 10px 0;
                }
            }
        }
        .btn, .btn_transparent{
            width: 100%;
        }
    `}
`
export const Partners = styled.div`
    h2{
        margin: 0 auto 70px;
    }
    .logos{
        width: 90%;
        margin: 0 auto 100px;
        display: flex;
        align-items: center;

        img{
            width: 200px;
            height: fit-content;
            margin-right: 30px;
        }
    }
`
