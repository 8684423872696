import React, { useState, useEffect } from "react"
import { Container } from "./styles.js"
import { NavLink, Link } from "react-router-dom"

const MobileMenu = ({isMenuOpen}) => {
    return (
        <Container style={{transform: isMenuOpen ? `translateX(0)` : `translateX(100%)`}}>
            <NavLink to='/' exact>Главная</NavLink>
            <NavLink to='/about'>Услуги</NavLink>
            <NavLink to='/legalization'>Легализация</NavLink>
            <NavLink to='/vacancies' activeClassName="active">Вакансии</NavLink>
            <NavLink to='/blog' activeClassName="active">Блог</NavLink>
            <NavLink to='/feedbacks' activeClassName="active">Отзывы</NavLink>
        </Container>
    )
}

export default MobileMenu