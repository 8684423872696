import React, { useState, useEffect } from "react"
import { Container, VacancyWrapper, Text, Info, ShortInfo, CityInfo, Intro, Buttons, AdditionalInfo, Video } from "./styles.js"
import cityIcon from '../../images/pin.svg'
import Map from './map.js'
import { Button } from '../buttons/buttons.js'
import { Link, useParams } from 'react-router-dom'
import coins from '../../images/coins.svg'
import persons from '../../images/persons.svg'
import home from '../../images/home.svg'
import car from '../../images/car.svg'
import tools from '../../images/tools.svg'
import { vacancyData } from "../../data.js"
import Slide from 'react-reveal/Slide'
import Gallery from './gallery.js'

const VacancyPage = ({ openModal }) => {
    const [vacancy, setVacancy] = useState(null)

    const params = useParams()
    const vacancyId = params.id

    useEffect(() => {
        vacancyData.forEach(element => {
            if (element.id == vacancyId) {
                setVacancy(element)
                return
            }
        })
    })

    return (
        vacancy == null ? <null /> :
            <Container>
                <Slide top>
                    <Intro style={{ background: `url(${vacancy.img})` }}>
                        <h3>{vacancy.name}</h3>
                        <div className='info_item'><img src={cityIcon} alt="img" className='icon' /> {vacancy.city}</div>
                        <Button onClick={openModal}> Оставить заявку</Button>
                    </Intro>
                </Slide>
                <VacancyWrapper>
                    <Text>{vacancy.factoryInfo}</Text>
                    <ShortInfo className='mobile_short_info'>
                        <div className="item"><img src={coins} alt='img' /> <div>{vacancy.salary[0]}</div></div>
                        <div className="item"><img src={home} alt='img' /> <div>{vacancy.housing[0]}</div></div>
                        <div className="item"><img src={car} alt='img' /> <div>{vacancy.transport}</div></div>
                        <div className="item"><img src={tools} alt='img' /> <div>Одежда и инструменты выдаются</div></div>
                        {/* <div className="item"><img src={quarantine} alt='img' /> <div>Предоставляем жилье для прохождения <a href="/article/2" target="_blank">карантина</a></div></div> */}
                        <div className="item"><img src={persons} alt='img' /> <div>{vacancy.countPerson}</div></div>
                    </ShortInfo>
                    <div className="flex">
                        <Info>
                            <div className='info_block'>
                                <p className='heading'>Оплата: </p>
                                <ul>
                                    {vacancy.salary.map((data, key) => {
                                        return (
                                            <li>{data}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className='info_block'>
                                <p className='heading'>График работы: </p>
                                <ul>
                                    {vacancy.schedule.map((data, key) => {
                                        return (
                                            <li>{data}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className='info_block'>
                                <p className='heading'>Проживание: </p>
                                <ul>
                                    {vacancy.housing.map((data, key) => {
                                        return (
                                            <li>{data}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className='info_block'>
                                <p className='heading'>Обязанности: </p>
                                <ul>
                                    {vacancy.responsibilities.map((data, key) => {
                                        return (
                                            <li>{data}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className='info_block'>
                                <p className='heading'>Требования: </p>
                                <ul>
                                    {vacancy.requirements.map((data, key) => {
                                        return (
                                            <li>{data}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className='info_block'>
                                <p className='heading'>Мы предлагаем: </p>
                                <ul>
                                    {vacancy.offers.map((data, key) => {
                                        return (
                                            <li>{data}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </Info>
                        <ShortInfo>
                            <div className="item"><img src={coins} alt='img' /> <div>{vacancy.salary[0]}</div></div>
                            <div className="item"><img src={home} alt='img' /> <div>{vacancy.housing[0]}</div></div>
                            <div className="item"><img src={car} alt='img' /> <div>{vacancy.transport}</div></div>
                            <div className="item"><img src={tools} alt='img' /> <div>{vacancy.clothes}</div></div>
                            <div className="item"><img src={persons} alt='img' /> <div>{vacancy.countPerson}</div></div>
                        </ShortInfo>
                    </div>
                    {
                        vacancy.video == null ? null :
                            <Video
                                width="590" height="345"
                                src={vacancy.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                            </Video>
                    }
                    <Gallery photos={vacancy.houseImages} />
                    <CityInfo className='flex'>
                        <div className='text'>
                            <h3>Информация о городе</h3>
                            <p>{vacancy.cityInfo}</p>
                        </div>
                        <Map cityLat={vacancy.cityLat} cityLng={vacancy.cityLng} className="map_block" />
                    </CityInfo>
                    <Buttons>
                        <Button onClick={openModal}>Оставить заявку</Button>
                        <Link to='/vacancies'><Button>Все вакансии</Button></Link>
                    </Buttons>
                </VacancyWrapper>
            </Container>
    )
}

export default VacancyPage