import React, { useRef } from "react"
import { Container, ContactInfo, ContactForm, TopBtn } from './styles.js'
import { SectionTitle } from '../titles/index.js'
import mail from '../../images/mail.svg'
import phone from '../../images/telephone.svg'
import { Button } from '../buttons/buttons.js'
import { useForm } from '@formspree/react'

const Contact = ({ myRef }) => {
    const [state, handleSubmit] = useForm("xvodvyvr")

    return (
        <Container ref={myRef}>
            <TopBtn onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                <span className='arrow' />
            </TopBtn>
            <SectionTitle>Контакты</SectionTitle>
            <div className='flex'>
                <ContactInfo>
                    <div>
                        NIP 118 171 53 34 <br />
                        REGON 362315588 <br />
                        KRAZ 14554
                    </div>
                    <div>
                        <span>Офис:</span>
                        <a href='https://goo.gl/maps/Wt6oagEXjhDtVPMq5' target='_blank'>
                            ul. Warszawska 55 <br />
                            05-092 Łomianki
                        </a>
                    </div>
                    <div>
                        <a href="tel:+48503864611"><img src={phone} alt='mail' /> +48 790 535 315</a>
                        <a href="mailto:biuro@formika-praca.pl"><img src={mail} alt='mail' /> biuro@formika-praca.pl</a>
                    </div>
                    <div>
                        <span>Наши рекрутеры:</span> 
                        <a href="tel:+48790535315"> Анастасия +48 790 535 315</a>
                        <a href="tel:+48781082783"> Мария +48 781 082 783</a>
                    </div>
                </ContactInfo>
                <ContactForm action="https://formspree.io/f/xvodvyvr" method="POST">
                    <input placeholder='Ваше имя' type="text" name="Имя" required />
                    <input placeholder='Адрес e-mail' type="email" name="Email" required />
                    <textarea placeholder='Сообщение' rows="6" type="text" name="Сообщение" required />
                    <Button type="submit">Отправить</Button>
                </ContactForm>
                <ContactInfo className='info_mobile'>
                    <div>
                        NIP 118 171 53 34 <br />
                        REGON 362315588 <br />
                        KRAZ 14554
                    </div>
                </ContactInfo>
            </div>
        </Container>
    )
}

export default Contact