import styled from 'styled-components'
import bg from '../../images/intro_bg.webp'
import media from 'styled-media-query'

export const Container = styled.div`
    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 -100px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    
    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
    }

    ${media.lessThan('1070px')`
        background-position: center;
    `}

    ${media.lessThan('768px')`
        background-position: center;
    `} 
`
export const Logo = styled.img`
    position: relative;
    z-index: 1;
    width: 70%;
    margin-top: 75px;
    
    ${media.lessThan('768px')`
        width: 95%;
    `}
`