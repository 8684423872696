import React, { useState, useEffect } from "react"
import { Container } from "./styles.js"
import { Button } from '../buttons/buttons.js'
import { Link } from 'react-router-dom'
import Slide from 'react-reveal/Slide'

const AboutBlock = ({ myRef, openModal }) => {
    return (
        <Container ref={myRef}>
            <Slide left>
                <div className='text'>
                    <h2 className='title'>Formika гарантирует доступную, легальную работу в Польше для каждого!</h2>
                    <span className='line' />
                    <p>Закажите обратный звонок и мы поможем подобрать Вам вакансию или ознакомтесь с предложениями по <a href="/vacancies" target="_blank">ссылке!</a></p>
                    <Button className='button' onClick={openModal}>
                        <div className='btn_text'>Заказать звонок</div></Button>
                </div>
            </Slide>
            <Slide right>
                <div className='img_block' />
            </Slide>
        </Container>
    )
}

export default AboutBlock