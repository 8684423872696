import React, { useState, useEffect } from "react"
import { Container, BlogItem } from "./styles.js"
import { SectionTitle } from "../titles"
import { Link } from 'react-router-dom'
import { Button } from "../buttons/buttons.js"
import { blogData } from "./data.js"

const BlogIntro = () => {
  const [allPosts, setAllPosts] = useState([])
  const [posts, setPosts] = useState([])

  useEffect(() => {
    setAllPosts(blogData.sort(() => Math.random() - 0.5))
    setPosts(allPosts.slice(0, 3))
  }, [allPosts])

  return (
    <Container>
      <SectionTitle>Блог</SectionTitle>
      <p className='subtitle'>Все, что Вам надо знать о работе в Польше!</p>
      <div className='flex'>
        {posts.map((data, key) => {
          let link = "/article/" + data.id
          return (
            <Link to={link} className='blog_item'>
              <BlogItem>
                <img src={data.img} alt='img' />
                <h2>{data.name}</h2>
                <p>{data.desc}</p>
              </BlogItem>
            </Link>
          )
        })}
      </div>
      <Link to='/blog'><Button>Читать дальше</Button></Link>
    </Container>
  )
}

export default BlogIntro