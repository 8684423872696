import styled from 'styled-components'
import postsBg from '../../images/blog_bg.webp'
import media from 'styled-media-query'

export const Container = styled.div`
    padding: 50px 0 100px;
    width: 87%;
    margin: 0 auto;
    background: #FFFBEF;

    .flex{
        display: flex;
        justify-content: space-between;

        ${media.lessThan('768px')`
            flex-direction: column;
        `}
    }

    h2{
        margin: 0 auto 30px;
    }
    .subtitle{
        font-family: 'Roboto Condensed', sans-serif;
        text-align: center;
        font-size: 20px;
        margin-bottom: 90px;
        color: #59506D;

        ${media.lessThan('768px')`
            margin-bottom: 50px;
        `}
    }
    .blog_item{
        width: 30%;
        
        ${media.lessThan('768px')`
            width: 85%;
            margin: 0 auto 30px;
        `}
    }

    ${media.lessThan('768px')`
        width: 95%; 
        padding: 0 0 50px;

        button{
            margin-top: 30px;
        }
    `}
`
export const BlogItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
    img{
        border-radius: 50%;
        width: 170px;
        height: 170px;
        object-fit: cover;
    }

    h2{
        margin: 40px 0 16px;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 22px;
        color: #FBAF43;
        letter-spacing: 0.6px;
        text-align: center;
    }

    p{
        text-align: center;
        font-family: 'Roboto Condensed', sans-serif;
        line-height: 22px;
        width: 85%;
    }
`
// Blog page

export const PostsContainer = styled.div`
    background: rgba(0,0,0,0.04);
    padding-bottom: 100px;
`
export const PostsIntro = styled.div`
    background-image: url(${postsBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 -200px;
    height: 50vh;
    position: relative;
    display: flex;
    align-items: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 32px;

    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
    }

    h2{
        position: relative;
        z-index: 1;
        color: #fff;
        border-bottom: 5px solid rgba(251, 175, 67, 0.5);
        padding-left: 70px;
        line-height: 3;

        ${media.lessThan('1100px')`
            line-height: 1.5;
            padding-right: 20px;
        `}

        ${media.lessThan('768px')`
            padding-left: 30px;
            font-size: 26px;
            line-height: 1.5;
            width: 75%;
            padding-bottom: 15px;
            padding-top: 45px;
        `}
    }
    ${media.lessThan('768px')`
        background-position: center;
    `}
`
export const Posts = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    padding-top: 175px;
    width: 85%;
    margin: 0 auto;

    ${media.lessThan('1024px')`
        grid-template-columns: 1fr 1fr;
    `}

    ${media.lessThan('768px')`
        grid-template-columns: 1fr;
        padding-top: 105px;
    `}
`
export const InfoBlock = styled.div`
    border-radius: 1rem;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 20%);
    background: #fff;
    font-family: 'Roboto Condensed', sans-serif;

    .img_block{
        position: relative;
        
        img{
            object-fit: cover; 
            width: 100%;
            height: 200px;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
        } 
        .date{
            position: absolute;
            bottom: 15px;
            left: 20px;
            color: #fff;
            background: rgba(0,0,0,0.6);
            padding: 5px 20px;
            border-radius: 1rem;
            font-size: 14px;
        }
    }

    .info{
        padding: 20px 10px 25px 20px;
    }

    h3{
        font-size: 24px;
        color: rgba(0,0,0,0.9);
        transform: translateY(-4px); 
        border-top: none;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;

        ${media.lessThan('768px')`
            font-size: 22px;
        `}
    }

    p{
        color: rgba(0,0,0,0.5);
        margin-top: 10px;
        line-height: 22px;
    }

    button{
        background: #FBAF43;
        padding: 12px 42px 12px 20px;
        border-radius: 3px;
        color: #fff;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 16px;
        letter-spacing: 0.8px;
        position: relative;
        margin-top: 20px;
        transition: all 0.1s linear;
        // width: 45%;

        .arrow{
            position: absolute;
            top: 50%;
            right: 16px;
            width: 10%;
            height: 2px;
            background-color: #fff;
            box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
            margin-left: 7px;

            :after, :before{
                content: '';
                position: absolute;
                width: 50%;
                height: 2px;
                right: -3px;
                background-color: #fff;
            }

            :after{
                top: -2px;
                transform: rotate(45deg);
            }
            :before{
                top: 2px;
                box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
                transform: rotate(-45deg);
            }
        }

        :hover{
            box-shadow: 0px 0px 25px 0px rgb(0 0 0 / 30%);
            transform: scale(1.05);
        }
    }
`
//Article page

export const ArticleIntro = styled.div`
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    height: 50vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto Condensed', sans-serif;

    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
    }

    .title{
        position: relative;
        z-index: 1;
        color: #fff;
        font-weight: 600;
        -webkit-letter-spacing: 0.8px;
        -moz-letter-spacing: 0.8px;
        -ms-letter-spacing: 0.8px;
        letter-spacing: 0.8px;
        font-size: 50px;
        margin-top: 70px;
        margin-bottom: 10px;
        text-align: center;
    }
    .date{
        color: #FBAF43;
        position: relative;
        z-index: 1;

        img{
            width: 20px;
            margin-right: 5px;
        }
    }
    ${media.lessThan('768px')`
        .title{
            font-size: 34px;
            text-align: center;
            padding: 0 20px;
        }
    `}
`
export const Text = styled.div`
    width: 60%;
    margin: 100px auto 150px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 19px;
    line-height: 26px;
    color: rgba(0,0,0,0.8);

    .links{
        font-size: 14px;
        margin-bottom: 30px;
        color: #FBAF43;

        span{
            border: solid #FBAF43;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            margin: 0 5px;
        }
    }

    p{
        margin-bottom: 17px;
    }
    h6{
        font-size: 24px;
        margin-bottom: 30px;
        margin-top: 35px;
    }
    ul{
        list-style: none;
        margin-bottom: 17px;

        li{
            margin-bottom: 10px;
            position: relative;
            margin-left: 22px;
            
            :before{
                content: " ";
                position: absolute;
                width: 5px;
                height: 5px;
                border: 2px solid #FBAF43; 
                border-radius: 50%;
                left: -20px;
                top: 35%;
            }
        }   
    }

    ${media.lessThan('1024px')`
        width: 75%;
    `}
    ${media.lessThan('768px')`
        width: 88%;
        margin: 50px auto 60px;
    `}
`
export const ReadAlso = styled.div`
    position: relative;
    padding-bottom: 100px;

    h2{
        margin: 0 auto 30px 140px;
    }
    .article{
        width: 95%;
        box-shadow: none;
      
        img{
            height: 190px;
        }
        h3{
            padding: 20px;
            font-size: 22px;
            transform: translateY(0px);  
            border: 1.5px solid #FBAF43;
            border-top: 0;
        }
    }

    .slick-list{
        width: 80%;
        margin: 0 auto;
    }
    .slick-prev, .slick-next{
        ::before{
            color: #FBAF43; 
            font-size: 32px;
        }
    }
    .slick-prev{
        left: 75px !important;
    }
    .slick-next{
        right: 75px !important;
    }

    ${media.lessThan('1024px')`
        .slick-prev, .slick-next{
            display: none !important;
        }

        .slick-list{
            padding-bottom: 30px;
            width: 87%;
        }

        .article{
            width: 96%;
            margin: 0 auto;
        }
        .slick-dots li button:before{
            color: #FBAF43;
            background: #FBAF43;
            position: relative;
            z-index: 1;
            border-radius: 50%;
            font-size: 14px;
        }
        h2{
            margin: 0 auto 50px;
        }
    `}
`