import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl'
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

mapboxgl.accessToken = 'pk.eyJ1IjoiYW5hc3Rhc2l5YW0iLCJhIjoiY2tybmQxYTVlMWowNTMxbGloMTJ1d2JqZiJ9.rhzCQJapiKmgCAhzl7R8zA';

export default function Map({ cityLat, cityLng }) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(cityLng);
    const [lat, setLat] = useState(cityLat);
    const [zoom, setZoom] = useState(10);

    useEffect(() => {
        if (map.current) return;

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom,
        })
    })

    return (
        <div className='text'>
            <div ref={mapContainer} className="map-container" />
        </div>
    );
}


// import React, { useRef, useEffect, useState } from 'react'
// import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
// // import 'mapbox-gl/dist/mapbox-gl.css'

// // import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp'

// // eslint-disable-next-line import/no-webpack-loader-syntax
// import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

// export default function Mapp({ cityLat, cityLng }) {

// // ReactMapboxGl.workerClass = MapboxWorker

// // eslint-disable-next-line import/no-webpack-loader-syntax
// ReactMapboxGl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

// const Map = ReactMapboxGl({
//     accessToken:
//         'pk.eyJ1IjoiYW5hc3Rhc2l5YW0iLCJhIjoiY2tybmQxYTVlMWowNTMxbGloMTJ1d2JqZiJ9.rhzCQJapiKmgCAhzl7R8zA'
// });

//     return (
//         <>
//         <Map
//             style="mapbox://styles/mapbox/streets-v9"
//             containerStyle={{
//                 height: '300px',
//                 width: '350px'
//             }}
//         >
//             <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
//                 <Feature coordinates={[ 52.409538, 16.931992]} />
//             </Layer>
//         </Map>
//         </>
//     );
// }



// import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp'
// // eslint-disable-next-line import/no-webpack-loader-syntax
// import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

// import 'mapbox-gl/dist/mapbox-gl.css'

// export const useMapbox = (opts) => {
//   mapboxgl.workerClass = MapboxWorker
// //   mapboxgl.accessToken = "public/private pk.eyJ1IjoiYW5hc3Rhc2l5YW0iLCJhIjoiY2tybmQxYTVlMWowNTMxbGloMTJ1d2JqZiJ9.rhzCQJapiKmgCAhzl7R8zA"

//   const init = (sources) => {
//     const [poisA, poisB, poisC] = sources

//     // const map = new mapboxgl.Map(opts)
//     // map.on('load', () => {
      
//     // })

//     const map = new mapboxgl.Map({
//         container: this.mapContainer.current,
//         style: 'mapbox://styles/mapbox/streets-v11',
//         center: [-70.9, 42.35],
//         zoom: 5
//     });

//     return map
//   }

//   return init
// }

// export default useMapbox