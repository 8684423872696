import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 175px;
    padding-bottom: 70px;

    input, textarea{
        padding: 15px 17px;
        margin-bottom: 17px;
        width: 35%;
        border: 1.5px solid #FBAF43;
        color: rgba(0,0,0,0.9);
    }

    ::placeholder{
        font-size: 16px;
        color: rgba(0,0,0,0.9);
    }
`