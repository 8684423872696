import styled from "styled-components"
import media from 'styled-media-query'

export const Container = styled.div`
    padding-bottom: 120px;
    font-family: 'Roboto Condensed', sans-serif;
    position: relative;
    
    .flex{
        display: flex;
        justify-content: space-between;

        ${media.lessThan('768px')`
            flex-direction: column;
        `}
    }

    ${media.lessThan('768px')`
        padding-bottom: 50px; 
    `}
`
export const VacancyWrapper = styled.div`
    padding: 70px 150px 40px 150px;
    
    ${media.lessThan('1200px')`
        padding: 70px 90px 40px 90px; 
    `}
    
    ${media.lessThan('1024px')`
        padding: 70px 30px 0 30px; 
    `}

    .mobile_short_info{
        display: none;
        margin: 0 auto 50px;
        width: fit-content;
        padding: 20px;
       
        ${media.lessThan('768px')`
            display: block;
        `}

        // ${media.lessThan('450px')`
        //     width: fit-content;
        // `}
    }
`
export const Text = styled.p`
    margin: 0 auto 70px;
    width: 90%;
    line-height: 24px;
    color: rgba(0,0,0,0.6);
    font-weight: 600;

    ${media.lessThan('768px')`
        width: 100%; 
    `}
`
export const Info = styled.div`
    width: 100%;
    padding-left: 30px;

    .info_block{
        margin-bottom: 40px;
        
        :last-child{
            margin-bottom: 0;
        }
    }

    .heading{
        color: #222123;
        font-size: 24px;
        margin-bottom: 15px;
        margin-left: 20px;
    }

    ul{
        list-style: none;

        li{
            margin-bottom: 5px;
            position: relative;
            color: rgba(0,0,0,0.8);
            margin-left: 22px;
            font-size: 18px;
            
            :before{
                content: " ";
                position: absolute;
                width: 5px;
                height: 5px;
                border: 2px solid #FBAF43; 
                border-radius: 50%;
                left: -20px;
                top: 25%;
            }
        }   
    }
    ${media.lessThan('768px')`
        padding-left: 0; 
    `}
`
export const CityInfo = styled.div`
    margin: 130px auto 0;
    width: 90%;

    h3{
        font-size: 30px;
        color: #FBAF43;
        letter-spacing: 0.5px;
    }

    .text{
        width: 50%;
    }

    p{
        line-height: 24px;
        margin-top: 18px;
        color: rgba(0,0,0,0.8);
        width: 90%;
    }

    .map{
        width: 50%; 
        position: relative;
        z-index: 1;
    }
    .map-container {
        height: 350px;
        width: 100%;
    }

    ${media.lessThan('1100px')`
        width: 100%;
    `}

    ${media.lessThan('768px')`
        margin: 80px auto 0;
        width: 100%;
        
        .text{
            width: 100%;
        }
        p{
            width: 100%;
            margin-bottom: 20px;
        }
    `}
`
export const Intro = styled.div`
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: 0 -200px !important;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 200px;
    position: relative;

    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
    }

    h3{
        position: relative;
        z-index: 1;
        color: #fff;
        font-size: 38px;
        margin-bottom: 15px;
        letter-spacing: 2px;
        margin-top: 70px;
       
        ${media.lessThan('768px')`
            text-align: center;
        `}
    }

    .info_item{
        font-size: 26px;
        color: #FBAF43;
        z-index: 1;
        posiztion: relative;
        
        ${media.lessThan('768px')`
            text-align: center;
        `}
    }

    .icon{
        width: 25px;
    }

    button{
        position: relative;
        z-index: 1;
        display: inline-block;
        margin-left: initial;
        margin-top: 50px;
        border-radius: 0.5rem;
    }

    ${media.lessThan('768px')`
        padding: 0 10px;
        background-position: center !important;
        align-items: center;

        h3{
            font-size: 30px;
        }
        .info_item{
            font-size: 22px;
        }
        button{
            margin: 50px auto 0;
        }
    `}
`

export const ShortInfo = styled.div`
    .item{
        display: flex;
        margin-bottom: 20px;
        font-size: 18px;
        color: rgba(0,0,0, 0.9);

        :last-child{
            margin-bottom: 0; 
        }
    }

    img{
        width: 25px;
        height: 100%;
        margin-right: 15px;
    }

    a{
        text-decoration: underline;
    }

    border: 1px solid #FBAF43;
    width: 33%;
    padding: 30px;
    border-radius: 10px;
    height: fit-content;

    ${media.lessThan('768px')`
        display: none; 
    `}
`
export const Video = styled.iframe`
    display: flex;
    justify-content: center;
    margin: 100px auto 0;

    ${media.lessThan('768px')`
        margin: 50px auto 0;
        width: -webkit-fill-available;
    `}
`
export const ModalWindow = styled.div`
    // display: ${({ isModalOpen }) => (isModalOpen ? 'none' : 'block')};
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 43, 66, 0.5);
    justify-content: center;
    align-items: center;
    text-align: center;

    .form{
        background-color: #ffffff;
        padding: 25px;
        box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.25);
        width: 30%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    
    .modal_title{
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 10px;
    }

    .modal_text{
        margin-bottom: 25px;
        color: rgba(0,0,0,0.7);
    }

    form{
        display: flex;
        flex-direction: column;

        input, select{
            color: rgba(0,0,0,0.7);
            font-size: 16px;
            border: 1px solid #919191;
            border-radius: 0.5rem;
            padding: 13px 15px;
            margin-bottom: 15px;
            font-family: 'Roboto Condensed', sans-serif;

            ::placeholder{
                color: rgba(0,0,0,0.7) !important; 
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 16px;
            }
        }

        button{
            color: #fff;
            border-radius: 0.5rem;
            background: #FBAF43;
            width: 100%;
            padding: 12px 0;
            font-size: 16px;
            font-family: 'Roboto Condensed', sans-serif;
            letter-spacing: 0.8px;
            margin-top: 10px;
        }
    }

    ${media.lessThan('768px')`
        .form{
            width: 80%;
        }
    `}
`
export const Buttons = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 120px;

    button{
        background: #FBAF43;
        margin: 0; 
        border-radius: 0.5rem;
        transition: all 0.2s linear;

        :first-child{
            margin-right: 15px;
        }

        :last-child{
            background: transparent;
            border: 2px solid #FBAF43;
            color: #FBAF43;
            

            :hover{
                background: #FBAF43;
                color: #fff;
            }
        }

        :hover{
            :after{
                display: none;
            }
        }
    }

    ${media.lessThan('470px')`
        flex-direction: column;
        margin-top: 70px;

        button{
            width: 100%;

            :last-child{
                margin-top: 15px;
            }
        }
    `}
`
export const CloseButton = styled.div`
    position: absolute;
    right: -10%;
    top: -7%;
    width: 25px;
    height: 25px;
    cursor: pointer;

    :before, :after {
        position: absolute;
        left: 10.5px;
        top: 3px;
        content: ' ';
        height: 28px;
        width: 2px;
        background-color: #FBAF43;
    }
    :before {
        transform: rotate(45deg);
    }
    :after {
        transform: rotate(-45deg);
    }

    ${media.lessThan('768px')`
        right: 0;
        top: -10%;
    `}
`
export const AdditionalInfo = styled.div`
    background: rgba(0,0,0,0.03);
    color: rgba(0,0,0,0.6);
    font-weight: 600;
    padding: 30px;
    border-radius: 0.5rem;
    line-height: 24px;
    width: 85%;
    margin: 80px auto 0;
    border: 1px solid rgba(0,0,0,0.2);

    p{
        margin-bottom: 15px;
    }
`
export const HouseGallery = styled.div`
    margin-top: 100px;
    position: relative;
    z-index: 4;

    .slick-list{
        height: 300px;

        ${media.lessThan('1024px')`
           height: 250px;
        `}

        ${media.lessThan('568px')`
           height: 300px;
        `}
    }
    .slick-slide{
        margin: 0 7px;
        height: 300px;
        div{
           height: 100%;
        }

        ${media.lessThan('1024px')`
           height: 200px;
        `}

        ${media.lessThan('568px')`
           height: 300px;
           margin: 0;
        `}
    }
    img{
        object-fit: cover;
        height: 100%;
    }
    .slick-arrow{
        :before{
            color: #FBAF43;
            font-size: 28px;
        }

        ${media.lessThan('1024px')`
            display: none !important;
        `}
    }
    .slick-prev{
        left: -45px;
    }
    .slick-next{
        right: -35px;
    }
    .slick-dots{
        ${media.lessThan('568px')`
           bottom: -45px;
        `}
    }
    .react-simple-image-viewer__modal-content {
        position: relative;
    }
`
export const Modal = styled.div`
    // position: relative;

    button{
        position: fixed;
        width: 40px;
        height: 40px;
        top: 80px;
        right: 10px;
        z-index: 1;

        :before, :after {
            position: absolute;
            left: 10.5px;
            top: 3px;
            content: ' ';
            height: 28px;
            width: 2px;
            background-color: #FBAF43;
        }
        :before {
            transform: rotate(45deg);
        }
        :after {
            transform: rotate(-45deg);
        }
    }
`