import styled from "styled-components"
import bg from '../../images/f_worker.webp'
import media from 'styled-media-query'

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Roboto Condensed', sans-serif;
    height: 100vh;
    padding-left: 120px;

    .text{
        width: 40%;
    }

    .title{
        font-size: 32px;
        color: rgba(0,0,0,0.9);
    }

    .line{
        height: 3px;
        width: 70px;
        margin: 20px 0;
        background: #FBAF43;
        display: block;
    }

    p{
        font-size: 22px;
        color: rgba(0,0,0,0.7);
        width: 80%;
        line-height: 25px;

        a{
            color: #FBAF43; 
        }
    }

    .button{
        margin-top: 50px;
        margin-right: auto !important;
        display: inline-block;
    }

    .img_block{
        width: 50%;
        height: 60%;
        background-image: url(${bg});
        background-size: cover;
        background-position: center;
    }

    ${media.lessThan('1200px')`
       padding-left: 60px;
    `}

    ${media.lessThan('1024px')`
       padding-left: 40px;

       .text{
            width: 60%;
        }
        .title{
            margin-right: 15px;
        }
    `}

    ${media.lessThan('768px')`
        padding: 0 30px;
        // height: auto;
        justify-content: center;

        .title{
            font-size: 26px;
        }

        .text{
            width: 90%;
        }

        .img_block{
            display: none;
        }
        .react-reveal{
            margin-bottom: 65px;
        }
    `}

    ${media.lessThan('550px')`
        text-align: center;
        padding: 70px 30px;

        .line{
            margin: 20px auto;
        }

        .text{
            width: 100%;
        }

        p{
            width: 100%;
        }
    `}
`