import styled from "styled-components"
import media from 'styled-media-query'

export const CloseButton = styled.div`
    position: absolute;
    right: -10%;
    top: -7%;
    width: 25px;
    height: 25px;
    cursor: pointer;

    :before, :after {
        position: absolute;
        left: 10.5px;
        top: 3px;
        content: ' ';
        height: 28px;
        width: 2px;
        background-color: #FBAF43;
    }
    :before {
        transform: rotate(45deg);
    }
    :after {
        transform: rotate(-45deg);
    }

    ${media.lessThan('768px')`
        right: 0;
        top: -10%;
    `}
`
export const ModalWindow = styled.div`
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 43, 66, 0.5);
    justify-content: center;
    align-items: center;
    text-align: center;

    .form{
        background-color: #ffffff;
        padding: 25px;
        box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.25);
        width: 30%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    
    .modal_title{
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 10px;
    }

    .modal_text{
        margin-bottom: 25px;
        color: rgba(0,0,0,0.7);
    }

    .form_wrapper{
        display: flex;
        flex-direction: column;

        input, select{
            color: rgba(0,0,0,0.7);
            font-size: 16px;
            border: 1px solid #919191;
            border-radius: 0.5rem;
            padding: 10px 15px;
            margin-bottom: 15px;
            font-family: 'Roboto Condensed', sans-serif;

            ::placeholder{
                color: rgba(0,0,0,0.7) !important; 
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 16px;
            }
        }

        button{
            color: #fff;
            border-radius: 0.5rem;
            background: #FBAF43;
            width: 100%;
            padding: 12px 0;
            font-size: 16px;
            font-family: 'Roboto Condensed', sans-serif;
            letter-spacing: 0.8px;
            margin-top: 10px;
        }

        p{
            color: red;
            margin-bottom: 10px;
            text-align: initial;
            font-size: 12px;
        }
    }
    .message{
        color: #FBAF43;
        font-size: 36px;
        font-weight: 700;
        margin: auto;
    }

    .react-tel-input{
        margin-bottom: 15px;
        height: 47px;
        display: flex;
        justify-content: end;

        input{
            height: 100%;
            width: 87%;
            margin-left: auto;

            ${media.lessThan('768px')`
                width: 84%;
            `}
        }
    }

    .flag-dropdown{
        height: 70%;
        top: 50% !important;
        transform: translateY(-50%);
        left: 0;
    }

    ${media.lessThan('768px')`
        .form{
            width: 80%;
        }
    `}
`