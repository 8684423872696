import styled from 'styled-components'
import media from 'styled-media-query'
import bg from '../../images/contact.webp'

export const Container = styled.section`
    padding: 80px 170px 100px 150px;
    background: #1F2C30;
    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 -70px;
    position: relative;

    .flex{
        display: flex;
        justify-content: space-between;
        
        ${media.lessThan('768px')`
            flex-direction: column;
        `} 
    }

    h2{
        color: #fff;
        margin-bottom: 30px;
        
        ${media.lessThan('768px')`
            margin: 0 auto 40px;
        `}
    }

    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
    }

    ${media.lessThan('1068px')`
        background-position: center;
        padding: 50px 40px 70px 40px;
    `}

    ${media.lessThan('768px')`
        padding: 100px 30px 70px 30px;
    `} 

    .info_mobile{
        display: none;
        margin-top: 50px;
        
        ${media.lessThan('768px')`
            display: block;
        `}
    }
`
export const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 40%;
    position: relative;
    z-index: 1;
    margin-top: 30px;

    input, textarea{
        margin-bottom: 12px;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 16px;
        color: #fff;
        background: transparent;
        border-bottom: 2px solid #FBAF43;
        opacity: .8;
        margin-bottom: 25px;
        padding-bottom: 15px;
    }

    input::placeholder, textarea::placeholder{
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 16px;
        color: #fff;
    }

    button{
        margin-right: inherit;
    } 
    
    ${media.lessThan('768px')`
        width: 100%;
        margin-top: 50px;

        button{
            margin: 30px auto 0;
        }
    `} 
`
export const ContactInfo = styled.div`
    color: #fff;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    position: relative;
    z-index: 1;

    div{
        margin-top: 30px;
        line-height: 24px;

        span{
            color: #FBAF43;
            margin-bottom: 5px;
            display: block;
        }
    }

    a{
        display: flex;

        img{
            width: 20px;
            margin-right: 7px;
            height: 100%;
        }
    }
    a:first-child{
        margin-bottom: 5px;
    }

    ${media.lessThan('768px')`
        div:first-child{
            display: none;
        }
        div{
            text-align: center;
        }
        div:last-child{
            display: flex;
            flex-direction: column;
            align-items:center;
        }
        a{
            justify-content: center;
        }
    `}
`
export const TopBtn = styled.div`
    border: 1px solid #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    position: absolute;
    right: 60px;
    top: 40px;

    .arrow{
        height: 22px;
        width: 1px;
        background: #fff;
        position: relative;

        :after, :before{
            content: "";
            position: absolute;
            width: 1px;
            height: 13px;
            background: #fff;
            top: -1px;
        }

        :after{
            transform: rotate(40deg);
            right: 4px;
        }
        :before{
            transform: rotate(-40deg);
            left: 4px;
        }
    }

    ${media.lessThan('768px')`
        top: 30px;
        right: 50%;
        transform: translateX(50%);
    `}
`
