import React, { useState, useRef } from "react"
import { FeedbacksContainer, FeedbackItems, Stars, FeedbackButton } from "./styles.js"
import { feedbackData } from "./data.js"
import Form from './form.js'

const FeedbackPage = () => {
    const myRef = useRef(null)

    function executeScroll() {
        myRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <FeedbacksContainer>
            <FeedbackButton onClick={executeScroll}>Написать отзыв<span className='arrow' /></FeedbackButton>
            <FeedbackItems>
                {feedbackData.map((data, key) => {
                    return (
                        <>
                            <div className='date'>{data.date}</div>
                            <div className='feedback_item'>
                                <div className='name'>{data.name}</div>
                                <Stars>
                                    {data.stars.map((item, key) => {
                                        return (
                                            <div className='star'>{item}</div>
                                        )
                                    })}
                                </Stars>
                            </div>
                            <p className='text'>{data.text}</p>
                        </>
                    )
                })}
            </FeedbackItems>
            <div ref={myRef}/>
            <Form />
        </FeedbacksContainer>
    )
}

export default FeedbackPage