import styled from 'styled-components'
import media from 'styled-media-query'

export const Button = styled.button`
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
    letter-spacing: 0.8px;
    padding: 13px 30px;
    margin: 75px auto 0; 
    display: block;
    color: #fff;
    background: linear-gradient(90deg, rgba(230,81,92,1) 0%, rgba(240,136,94,1) 42%, rgba(251,175,67,1) 100%);
    position: relative;
    overflow: hidden;

    :after {
        background: #fff;
        content: "";
        height: 155px;
        left: -75px;
        opacity: .2;
        position: absolute;
        top: -50px;
        transform: rotate(35deg);
        transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
    }

    :hover{    
        :after {
            left: 120%;
            transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
        }  
    } 
`
export const ArrowBtn = styled.button`
    border-radius: 50%;
    border: 2px solid #FBAF43;
    width: 55px;
    height: 55px;
    position: relative;
    z-index: 1;
    margin-bottom: 60px;

    :after {
        content: " ";
        display: block;
        opacity: 0;
        width: 59px;
        height: 59px;
        position: absolute;
        right: -5px;
        top: -5px;
        border-radius: 50%;
        border: 1px solid #fff;
        border-color: #fff transparent #fff transparent;

        @keyframes animation {
            0% {transform: rotate(0deg);}
            100% {transform: rotate(360deg);}
          }
    }

    :hover{
        :after{
            opacity: 1;
            animation: animation 1.2s linear infinite;
        }
    }

    .arrow {
        position: absolute;
        top: 50%;
        right: 13px;
        width: 50%;
        height: 2px;
        background-color: #FBAF43;
        box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
        transform: rotate(90deg);
    }
      
    .arrow::after, .arrow::before {
        content: '';
        position: absolute;
        width: 70%;
        height: 2px;
        right: -3px;
        background-color: #FBAF43;
    }
      
    .arrow::after {
        top: -6px;
        transform: rotate(45deg);
    }
      
    .arrow::before {
        top: 6px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
        transform: rotate(-45deg);
    }
`
export const BurgerBtn = styled.div`
    display: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 95px;
    transform: translateY(-50%);
    z-index: 100;

    .bar1, .bar2, .bar3 {
        width: 25px;
        height: 2px;
        background-color: #FFF8F2;
        margin: 6px 0;
        transition: 0.4s;
    }

    .bar1{
        transform: ${({ isMenuOpen }) => (isMenuOpen ? 'rotate(-45deg) translate(-5px, 6px)' : 'rotate(0) translate(0, 0)')};
    }

    .bar2{
        opacity: ${({ isMenuOpen }) => (isMenuOpen ? '0' : '1')};
    }

    .bar3{
        transform: ${({ isMenuOpen }) => (isMenuOpen ? 'rotate(45deg) translate(-5px, -6px)' : 'rotate(0) translate(0, 0)')};
    }

    ${media.lessThan('768px')`
        display: block; 
    `}
`