import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #141414;
    padding: 70px 100px;

    h2{
        color: #fff;
        margin-bottom: 100px;
    }

    .flex{
        display: flex;
        justify-content: space-between;

        ${media.lessThan('768px')`
            flex-direction: column;
            align-items: center;
        `}
    }

    ${media.lessThan('1024px')`
        padding: 70px 50px 70px;
    `} 

    ${media.lessThan('768px')`
        padding: 70px 30px 30px;
    `} 
`
export const Item = styled.div`
    color: rgba(255, 255, 255, 0.9);
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    width: 20%;

    ${media.lessThan('1024px')`
        width: 25%;
        margin-bottom: 15px;
    `} 

    ${media.lessThan('665px')`
        width: 90%;
        margin-bottom: 50px;
    `}
`
export const Img = styled.div`
    border: 2px solid #FBAF43;
    width: fit-content;
    padding: 25px;
    border-left: 0;
    border-top: 0;
    position: relative;
    margin: 0 auto 30px;

    img{
        width: 60px;
    }

    :after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 50%;
        width: 2px;
        background: #FBAF43;
    }

    :before{
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 2px;
        background: #FBAF43;
    }

    ${media.lessThan('665px')`
        margin: 0 auto 15px;
    `}
`