import React, { useState, useEffect } from "react"
import { Container } from "./styles.js"
import {Link} from 'react-router-dom'
import { SectionTitle } from '../titles/index.js'
import quotes from '../../images/quotes.svg'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

const FeedBacks = () => {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 920,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
        ]
    }

    return (
        <Container>
            <SectionTitle>Отзывы</SectionTitle>
            <Slider {...settings}>
                <div className='slide'>
                    <p className='feedback_text'>
                        Работала через эту фирму на заводе Hortex. Заработная плата всегда вовремя, условия жилья хорошие, в общем все понравилось.
                    </p>
                    <div>
                        <p className='name'>Валентина</p>
                        <p className='position'>Разнорабочая</p>
                    </div>
                    <img src={quotes} alt='img' className='quotes' />
                </div>
                <div className='slide'>
                    <p className='feedback_text'>
                        Работал на акумуляторном заводе , казалось будет тяжело а потом оказалось нормально . Платят своевременно, есть помощь от координаторов
                    </p>
                    <div>
                        <p className='name'>Виталий</p>
                        <p className='position'>Разнорабочий</p>
                    </div>
                    <img src={quotes} alt='img' className='quotes' />
                </div>
                <div className='slide'>
                    <p className='feedback_text'> Все обіцяне виконане у повному обсязі (переїзд, проживання, праця, заробітна плата). Дуже задоволений. Буду звертатись і надалі</p>
                    <div>
                        <p className='name'>Михайло</p>
                        <p className='position'>Зварювальник</p>
                    </div>
                    <img src={quotes} alt='img' className='quotes' />
                </div>
                <div className='slide'>
                    <p className='feedback_text'>Работаю от агенции 3 года. Очень доволен.проблем Не возникало ни с чем.</p>
                    <div>
                        <p className='name'>Олег</p>
                        <p className='position'>Сварщик</p>
                    </div>
                    <img src={quotes} alt='img' className='quotes' />
                </div>
                <div className='slide'>
                    <p className='feedback_text'>Вначале было ничего не понятно, поехал впервы, но по телефону мне все понятно объяснили и стало как-то легче.По итогу поехал я на работу в Познань на аккумуляторный завод.Условия работы были хорошие, платили своеременно. Шикарный хостел!</p>
                    <div>
                        <p className='name'>Виктор</p>
                        <p className='position'>Разнорабочий</p>
                    </div>
                    <img src={quotes} alt='img' className='quotes' />
                </div>
            </Slider>
            <Link to='/feedbacks'><button className='button'>Читать больше <span className='arrow' /></button></Link>
        </Container>
    )
}

export default FeedBacks