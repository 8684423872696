import React, { useState, useEffect } from "react"
import { ModalWindow } from "./styles.js"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Modal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [name, setName] = useState()
    const [tel, setTel] = useState()
    const [telViber, setTelViber] = useState()
    const [documentType, setDocumentType] = useState()
    const [isErrorName, setIsErrorName] = useState(false)
    const [isErrorTel, setIsErrorTel] = useState(false)
    const [isFormSend, setIsFormSend] = useState(false)

    function closeModal() {
        setIsModalOpen(false)
        document.body.style.overflowY = "scroll"
        setIsFormSend(false)
    }

    function setInputName(e) {
        setName(e.target.value)
        if (name !== undefined && name !== null) {
            setIsErrorName(false)
        }
    }

    // function setInputTel(formattedValue) {
    //     setTel(formattedValue)
    //     if (tel !== undefined && tel !== null) {
    //         setIsErrorTel(false)
    //     }
    // }

    function validateForm() {
        if (name !== undefined && telViber !== undefined && name !== null && telViber !== null) {
            console.log('xyi')
            sendMessage()
        } else if (name == undefined || tel == null) {
            setIsErrorName(!isErrorName)
            setIsErrorTel(!isErrorTel)
        }
    }

    let link = `https://api.telegram.org/bot1926026633:AAGilrMsntVe8OQWejB1znBXC-KtQ_KBzPk/sendMessage?chat_id=-1001557024724&text=<b>Name: </b> ${name}<b> Document type: </b>${documentType} <b> Phone: </b> ${telViber}&parse_mode=html`

    async function sendMessage() {
        await fetch(link)
            .then((response) => {
                setIsFormSend(true)
                console.log('great!')
            })
    }

    return (
        <ModalWindow  style={{ height: isFormSend ? `50%` : `auto` }} className="main_form">
            {isFormSend ? <p className='message'>Ваш запрос отправлен!<br/><span>Постараемся перезвонить как можно скорее</span></p> :
                <>
                    <h3>Бесплатная консультация</h3>
                    {/* <h3>Узнать цену или получить бесплатную консультацию</h3> */}
                    <input placeholder='Имя' name="Имя" required onChange={e => setInputName(e)} />
                    <select name="Документ" id="Документ" onChange={e => setDocumentType(e.target.value)}>
                        <option value="Карта побыта" selected>Карта побыта</option>
                        <option value="Карта резидента">Карта резидента ЕС</option>
                        <option value="Сталый побыт">Сталый побыт</option>
                    </select>
                    <PhoneInput
                        country={'pl'}
                        onChange={formattedValue => setTelViber(formattedValue)}
                        placeholder='Телефон'
                    />
                    <button onClick={validateForm}>Отправить</button>
                </>
                }
            </ModalWindow>
    )
}

export default Modal