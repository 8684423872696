import styled from 'styled-components'

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    color: #fff;
    height: 100vh;
    background: black;
    width: 55%;
    z-index: 3;
    transition: all .3s linear;

    a{
        margin-bottom: 20px;
        font-size: 20px;
        font-family: 'Roboto Condensed', sans-serif;
        position: relative;
        
        :last-child{
            margin-bottom: 0;
        }

        :after{
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -4px;
            left: 0;
            background: #FBAF43;
        }
    }
`