import React from "react"
import { BurgerBtn } from "./styles.js"
// import disableScroll from 'disable-scroll'

const Burger = ({isMenuOpen, setIsMenuOpen}) => {
    
    function buttonClicked () {
        setIsMenuOpen(!isMenuOpen)
        // isMenuOpen === false ? disableScroll.on() : disableScroll.off()
    }
    
    return (
        <BurgerBtn onClick={buttonClicked} isMenuOpen={isMenuOpen}>
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
        </BurgerBtn>
    )
}

export default Burger