import React, { useState, useRef } from "react"
import { Form, Input, Message, MessageMain } from "./styles.js"
import Rating from './rating.js'
import { Button } from "../buttons/buttons.js"

const FeedbackForm = ({myRef}) => {
    const [value, setValue] = useState('rating')
    const [selection, setSelection] = useState(0)
    const [isMessageShown, setIsMessageShown] = useState(false)
    const [name, setName] = useState()
    const [position, setPosition] = useState()
    const [feedback, setFeedback] = useState()
    const [isFormSend, setIsFormSend] = useState(false)
    const [isError, setIsError] = useState(false)

    let link = `https://api.telegram.org/bot1926026633:AAGilrMsntVe8OQWejB1znBXC-KtQ_KBzPk/sendMessage?chat_id=-1001557024724&text=<b>Name: </b> ${name}<b> Position: </b>${position} <b> Feedback: </b> ${feedback}<b> Stars: </b> ${value}&parse_mode=html`

    function validateForm() {
        if (name !== undefined && feedback !== undefined && name !== null && feedback !== null) {
            sendMessage()
        } else {
            setIsError(true)
        }
    }

    async function sendMessage() {
        await fetch(link)
            .then((response) => {
                setIsFormSend(true)
            })
    }

    return (
        <>
        {isFormSend ? <MessageMain>Спасибо за Ваш отзыв!</MessageMain> : 
        <Form myRef={myRef}>
            <Rating value={0} selection={selection} setSelection={setSelection} setValue={setValue} setIsMessageShown={setIsMessageShown} />
            <Message style={{ display: isMessageShown ? `block` : `none` }}>Спасибо за Вашу оценку!</Message>
            <div className="flex">
                <Input>
                    <label>Ваше имя<span>*</span></label>
                    <input placeholder="" name="Имя" required onChange={e => setName(e.target.value)} style={{borderColor: isError ? `red` : `#ff9933`}}/>
                </Input>
                <Input>
                    <label>Должность</label>
                    <input placeholder="" name="Должность" onChange={e => setPosition(e.target.value)}/>
                </Input>
            </div>
            <Input className="textarea">
                <label>Ваш отзыв<span>*</span></label>
                <textarea placeholder='' rows="6" type="text" name="Сообщение" required onChange={e => setFeedback(e.target.value)} style={{borderColor: isError ? `red` : `#ff9933`}}/>
            </Input>
            <input value={value} name="рейтинг" className='hidden' />
            <p className='note'>Поля помеченные <span>*</span> обязательны к заполнению</p>
            <Button onClick={validateForm}>Отправить</Button>
        </Form>
        }
        </>
    )
}

export default FeedbackForm