export const feedbackData = [
    {
        name: "Виталий",
        text: "Очень хорошая фирма, никогда не было никаких проблем ни с зарплатой, ни с чем либо другимОтдельное спасибо координатору Екатерине, которая всегда помогает на всех етапах работы и оформления документов",
        date: "22.08.2022",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Назар",
        text: "Доброго дня, хороша фірма, ніколи ніяких проблем з зарплатами і годинами не було, якщо якісь є проблеми фірма максимально старається їх вирішити...житло безкоштовне, праця подобається...рекомендую не пожалієте.",
        date: "11.10.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Лідія",
        text: " Доброго дня . Дякую Каті що зробила мені документи яка інша фірма не хотіла робити.Я дуже вдячна Каті і фірмі форміка.",
        date: "08.10.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Oksana, Робочий",
        text: "Вже не перший раз їду на роботу від цієї агенції. Умови хороші: проживання безкоштовне, проїзд оплачуємо але хто хоче може до місця праці добиратись сам, зарплата завжди вчасно, роблять документи всіх типів, повернення ПІТ. Зараз вже дають премії за хорошу роботу. Що стосується карантину то про суму яка потрібна на одну особу кажуть і умови також. Зрештою ніхто не заставляє вас проходити карантин саме від Форміки, якщо маєте інше місце. Рекомендую як добросовісного роботодавця.👍👍👍👍👍",
        date: "24.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Руслан",
        text: "Все круто мне нравися нашому куратору Катюхе РЕСПЕКТ!!!",
        date: "23.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Захар, Сервісовий",
        text: "Вітання,зп стабільно без затримок,загалом,не погано,дивлячись на який процес потрапити,з документами справді допомагають,житло безкоштовне,на роботу і з роботи возять 120 злотих за місяць!",
        date: "23.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Ірина",
        text: "Працювати в цій фірмі дуже добре тому що пропонують дуже хорошу працю в якій завжди е години виплата зарплати завжди вчасно затримок немае координатори дуже приемні з якими можливо вирішить усі питання. Я працюю на заводі Хортекс також продалась на карту побиту.",
        date: "23.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Руслан, Рабочий",
        text: "Мне понравилась ета фирма ришают все проблемы если связаны сроботой или касается непосретсвино стобой буду ехать с женой сново на ету фирму.",
        date: "23.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Евгения, Пакувальщица",
        text: "Фирма вовремя производит оплату..без задержек!! Руководство отличное!Кормят вкусно! Жильё отличное и бесплатное!!Работаю второй год и рекомендую вам!!!",
        date: "22.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Микола",
        text: "Всім довольний, робота непогана. Зарплата не найбільша в порівнянні з іншими але зато безкоштовне житло",
        date: "22.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Максим",
        text: "Фірма надійна ,зп вчасно,житло безкоштовно,працюю на Хортексі ,все влаштовує )",
        date: "22.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Даша, Різноробочий",
        text: "Фірма хороша.Зарплата вчасно.Допомагають з документами,житло безплатно. Всім рекомендую.",
        date: "21.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Леся",
        text: "Другий рік працюю від цієї агенції. Можу написати тільки позитивний відгук. Керівництво хороше, компетентне. Своєчасна виплата заробітної плати, хороші умови проживання, довіз до роботи, багато робочих годин. РЕКОМЕНДУЮ 👍👍👍",
        date: "21.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Сергий, Разнорабочый",
        text: "Усим рекомендую фирму формика. Координаторы отзывчыви уси пытаня спаперамы рышають в кратчайшие срокы выплата ЗП вовремя задержок не ма)))))))",
        date: "21.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Володя, Працівник",
        text: "Хороша фірма працюю 1.5 роки 0 проблем",
        date: "21.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Ольга, Різноробоча",
        text: "Хороша фірма,рекомендую.Житло безкоштовно, все,як обіцяють, так і є.Є різні вакансії.ЗП у Форміки весь час вчасно, години завжди звіряють. Можна завжди на них розраховувати. Приїхали 2 раз,на меблевий завод Forte Все влаштовує.",
        date: "20.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Женя, Монтажные работы",
        text: "Рекомендую!!!Работаю на акамуляторном заводе!Приехал первый раз в Польшу. Всё понравилось, жылье бесплатное,заработная плата приходит вовремя,любые вопросы решают без проблем, документы тоже делают без проблем.Только на эту фирму буду ехать.💪👌🌠🌠🌠🌠🌠",
        date: "20.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Тамара",
        text: "Відношення добре, житло та обіди безкоштовні.Зарплату платять вчасно в повному обсязі. Рекомендую.",
        date: "20.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Ирина",
        text: "Очень рекомендую агенство,хорошее отношение к людям,помощь во всех возникающих аопросах,своевременная оплата труда.",
        date: "20.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Віктор, Різноробочий",
        text: "Рекомендую, хороша фірма.працюю довгий час.Хороше відношення до працівників.",
        date: "20.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Олександр, Формувальщик акумуляторов",
        text: "Работаю на заводе EXIDE, робота нормальная, оплата хорошая, жилье отличное безплатно. Думаю задержусь здесь на долго.",
        date: "20.09.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Анна, Пакувальниця",
        text: "Працюю від фірми вже більше за рік. На данний час очікую на карту побиту. Працювала на двох проектах, як і всюди є свої нюанси, але загалом і вцілому фірму рекомендую. Своєчасна виплата платні, нормальне житло",
        date: "28.06.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Анатолий, Сварщик",
        text: "Работаю с компанией год, заработная плата приходит своевременно, любые проблемы и вопросы с документами координаторы компании помогают решить. Всем рекомендую.",
        date: "20.06.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
    {
        name: "Александр",
        text: "Добрый день. Работал от фирмы в Познани на акумулятоном заводе. Понравилось что на месте есть координатор. Оплата нормальная. Агенство помогает с документами",
        date: "18.06.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2606'
        ]
    },
    {
        name: "Ирина, Разнорабочая",
        text: "Благодарна за помощь с работой. Работала на заводе где делают печенье. Жилье дают бесплатно.",
        date: "15.06.2021",
        stars: [
            '\u2605', '\u2605', '\u2605', '\u2605', '\u2605'
        ]
    },
];