import React from "react"

const Star = ({ marked, starId }) => {
  return (
    <span data-star-id={starId} className="star" role="button" style={{ color: `#ff9933` }}>
      {marked ? '\u2605' : '\u2606'}
    </span>
  );
}

export default Star