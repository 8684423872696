import styled from 'styled-components';
import media from 'styled-media-query'

export const MainTitle = styled.h1`
    opacity: .9;
    line-height: 1;

    ${media.lessThan('571px')`
        font-size: 30px;
        width: 90%;
    `}
`;

export const SectionTitle = styled.h2`
    color: #000;
    font-size: 26px;
    font-family: 'Russo One', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    line-height: 1.6;
    position: relative;
    z-index: 1;
    width: fit-content;
    letter-spacing: 0.8px;
    
    :after{
        content: '';
        position: absolute;
        width: 100%;
        bottom: -5px;
        height: 3px;
        background: #FBAF43;
        right: 50%;
        transform: translateX(50%);
    }

    ${media.lessThan('768px')`
        font-size: 22px;
    `}
`

