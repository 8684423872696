import React, { useState, useEffect } from "react"
import { ModalWindow } from "./styles.js"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { CloseButton } from "../modal/styles.js"

const Modal = ({isModalOpen, setIsModalOpen}) => {
    // const [isModalOpen, setIsModalOpen] = useState(false)
    const [name, setName] = useState()
    const [tel, setTel] = useState()
    const [telViber, setTelViber] = useState()
    const [day, setDay] = useState()
    const [time, setTime] = useState()
    const [isErrorName, setIsErrorName] = useState(false)
    const [isErrorTel, setIsErrorTel] = useState(false)
    const [isFormSend, setIsFormSend] = useState(false)

    function closeModal() {
        setIsModalOpen(false)
        document.body.style.overflowY = "scroll"
        setIsFormSend(false)
    }

    function setInputName(e) {
        setName(e.target.value)
        if (name !== undefined && name !== null) {
            setIsErrorName(false)
        }
    }

    function validateForm() {
        if (name !== undefined && telViber !== undefined && name !== null && telViber !== null) {
            console.log('xyi')
            sendMessage()
        } else if (name == undefined || tel == null) {
            setIsErrorName(!isErrorName)
            setIsErrorTel(!isErrorTel)
        }
    }

    let link = `https://api.telegram.org/bot1926026633:AAGilrMsntVe8OQWejB1znBXC-KtQ_KBzPk/sendMessage?chat_id=-1001557024724&text=<b>Name: </b> ${name}<b> Day: </b>${day} <b> Time: </b>${time} <b>Phone: </b> ${telViber}&parse_mode=html`

    async function sendMessage() {
        await fetch(link)
            .then((response) => {
                setIsFormSend(true)
                console.log('great!')
            })
    }

    return (
        <div style={{ display: isModalOpen ? `flex` : `none` }} className="office_consultation_modal">
            <ModalWindow style={{ height: isFormSend ? `50%` : `auto` }}>
                {isFormSend ? <p className='message'>Ваш запрос отправлен!<br /><span>Постараемся перезвонить как можно скорее</span></p> :
                    <>
                        <h3>Выберите </h3>
                        {/* <h3>Узнать цену или получить бесплатную консультацию</h3> */}
                        <input placeholder='Имя' name="Имя" required onChange={e => setInputName(e)} />
                        <select name="День недели" id="День недели" onChange={e => setDay(e.target.value)}>
                            <option value="">Выберите день</option>
                            <option value="Понедельник">Понедельник</option>
                            <option value="Вторник">Вторник</option>
                            <option value="Среда">Среда</option>
                            <option value="Четверг">Четверг</option>
                            <option value="Пятница">Пятница</option>
                        </select>
                        <select name="День недели" id="День недели" onChange={e => setTime(e.target.value)}>
                            <option value="" selected>Выберите время</option>
                            <option value="9.00-10.00">9.00-10.00</option>
                            <option value="10.00-11.00">10.00-11.00</option>
                            <option value="11.00-12.00">11.00-12.00</option>
                            <option value="12.00-13.00">12.00-13.00</option>
                            <option value="13.00-14.00">13.00-14.00</option>
                            <option value="14.00-15.00">14.00-15.00</option>
                            <option value="15.00-16.00">15.00-16.00</option>
                        </select>
                        <PhoneInput
                            country={'pl'}
                            onChange={formattedValue => setTelViber(formattedValue)}
                            placeholder='Телефон'
                        />
                        <button onClick={validateForm}>Отправить</button>
                    </>
                }
            <CloseButton className='close-btn' onClick={closeModal} />    
            </ModalWindow>
        </div>
    )
}

export default Modal