import React, { useState, useEffect } from 'react'
import { Container } from './styles.js'
import {Button} from '../buttons/buttons.js'
import firebase from '../../firebase.js'

const AdminPage = () => {
    const [title, setTitle] = useState()
    const [location, setLocation] = useState()
    const [salary, setSalary] = useState(null)
    const [schedule, setSchedule] = useState(null)
    const [notes, setNotes] = useState(null)
    const [responsibilities, setResponsibilities] = useState([])
    const [responsibilitiesList, setResponsibilitiesList] = useState([])
    const [responsibilitiesInput, setResponsibilitiesInput] = useState([{ value: null }])
    const [offers, setOffers] = useState([])
    const [offersList, setOffersList] = useState([])
    const [offersInput, setOffersInput] = useState([{ value: null }])
    const [isRequired, setIsRequred] = useState(false)

    var db = firebase.firestore()

    function validateForm() {
        if (title !== null) {
            addVacancy()
        } else {
            setIsRequred(!isRequired)
        }
    }

    function addVacancy() {
        db.collection("vacancies").add({
            title: title,
            location: location,
            // description: description,
            // img: photo,
            // notes: notes,
            // salary: salary,
            // salaryNotes: salaryNotes,
            // schedule: schedule,
            // responsibilities: responsibilitiesList,
            // offers: offersList
        })
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                window.location.reload();
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    }

    return (
        <Container>
            <input placeholder='Название вакансии' onChange={e => setTitle(e.target.value)} required />
            <input placeholder='Местоположение' onChange={e => setLocation(e.target.value)} required />
            <input placeholder='Оплата' onChange={e => setSalary(e.target.value)} required />
            <input placeholder='' onChange={e => setSalary(e.target.value)} required />
            <Button onClick={validateForm} type='submit'>Добавить вакансию</Button>
        </Container>
    )
}

export default AdminPage