import React, { useState, useEffect } from "react"
import { Container, Item, Img } from "./styles.js"
import { SectionTitle } from "../titles/index.js"
import certificate from "../../images/certificate.svg"
import vacancy from "../../images/vacancy.svg"
import doc from "../../images/document.svg"
import team from "../../images/team.svg"

const Why = () => {
    return (
        <Container>
            <SectionTitle>Почему Formika?</SectionTitle>
            <div className="flex">
                <Item>
                    <Img><img src={certificate} alt="img" /></Img>
                    Легальное трудоустройство без посредников
                </Item>
                <Item>
                    <Img><img src={vacancy} alt="img" /></Img>
                    Только бесплатные вакансии
                </Item>
                <Item>
                    <Img><img src={doc} alt="img" /></Img>
                    Подготовка всех необходимых документов
                </Item>
                <Item>
                    <Img><img src={team} alt="img" /></Img>
                    Помощь координаторов на всех этапах трудоустройства
                </Item>
            </div>
        </Container>
    )
}

export default Why