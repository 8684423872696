import styled from "styled-components"
import bg from '../../images/legalization_intro.jpg'
import bg2 from '../../images/consultation_office.png'
import bg3 from '../../images/consultation_online.png'
import media from 'styled-media-query'

export const Container = styled.div`
    font-family: "Roboto Condensed", sans-serif;

    .mobile_form{
        display: none;
        justify-content: center;
        padding: 120px 0 0;

        ${media.lessThan('768px')`
            display: flex;
        `}
    }

    .office_consultation_modal{
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(37, 43, 66, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
`
export const Intro = styled.div`
    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 -170px;
    height: 80vh;
    color: #fff;
    position: relative;
    padding-top: 70px;
    
    :after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
    }

    .intro_content{
        position: relative;
        width: 80%;
        margin: 0 auto;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: -webkit-fill-available;
    }

    h2{
        font-family: 'Roboto Condensed', sans-serif; 
        font-weight: 600;
        letter-spacing: 0.8px;
        font-size: 42px;
        margin-bottom: 12px;
        width: 50%;

        .mobile_btn{
            display: none;
            width: 55px;
            height: 55px;
            margin: 50px auto 0;
            border: 1px solid #FBAF43;
            border-radius: 50%;
            align-items: center;
            justify-content: center;

            .arrow{
                height: 22px;
                width: 1px;
                background: #FBAF43;
                position: relative;
        
                :after, :before{
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 13px;
                    background: #FBAF43;
                    bottom: -2px;
                }
        
                :after{
                    transform: rotate(-40deg);
                    right: 4px;
                }
                :before{
                    transform: rotate(40deg);
                    left: 4px;
                }
            }
        }
    }

    ${media.lessThan('1200px')`
        background-position: center;

        .intro_content{
            width: 90%;
        }
    `}

    ${media.lessThan('1050px')`
        h2{
            font-size: 36px;
            margin-right: 20px;
        }
    `}

    ${media.lessThan('768px')`
        h2, p{
            padding-left: 30px;
        }
        h2{
            font-size: 38px;
            margin: 0 auto;
            text-align: center;
            width: 95%;
            padding: 0;

            .mobile_btn{
                display: flex;
            }
        }
        .main_form{
            display: none;
        }
    `}
`
export const Services = styled.div`
    padding: 100px 0 130px;

    h2{
        margin: 0 auto;
    }

    .services_block{
        width: 70%;
        margin: 90px auto 0;
        font-size: 18px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 60px;
    
        div{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        img{
            width: 50px;
        }

        p{
            margin-top: 20px;
            display: inline-block;
            text-align: center;
        }

        ${media.lessThan('1024px')`
            width: auto;
            grid-column-gap: 15px;
            grid-row-gap: 40px;
        `}

        ${media.lessThan('550px')`
            grid-template-columns: 1fr 1fr;
        `}
    }

    ${media.lessThan('1024px')`
        width: auto;
        padding: 100px 30px;
    `}
`
export const Price = styled.div`
    padding: 0 0 160px 0;
    
    h2{
        margin: 0 auto;
    }

    p{
        margin: 40px auto 100px;
        text-align: center;
        width: 50%;
        font-size: 19px;

        span{
            font-size: 22px;
            font-weight: 700;
        }

        ${media.lessThan('768px')`
            width: 100%;
            margin: 40px auto 70px;
        `}
    }

    .consultation{
        width: 70%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        button{
            color: #fff;
            border-radius: 0.8rem;
            background: #FBAF43;
            padding: 12px 30px;
            font-size: 16px;
            font-family: 'Roboto Condensed', sans-serif;
            letter-spacing: 0.8px;
            width: fit-content;
        }

        .consultation_block{
            padding: 30px;
            background: rgba(251, 175, 67, 0.1);
            border-radius: 0.7rem;
            box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
            flex: 1;
            background-image: url(${bg2});
            background-size: cover;
            background-repeat: no-repeat;

            :first-child{
                background-image: url(${bg3});
                margin-right: 60px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                ${media.lessThan('1024px')`
                    margin-right: 30px;
                `}

                ${media.lessThan('768px')`
                    margin: 0 0 35px 0;
                `}
            }

            .title{
                font-size: 26px;
                margin-bottom: 20px;
                font-weight: 500;
                color: #fff;
            }

            .list{
                line-height: 2;
                color: rgba(0, 0, 0, 0.8);
                margin-bottom: 40px;
                font-size: 18px;
                color: #fff;
            } 

            .price{
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #fff;

                span{
                    font-size: 24px;
                    font-weight: 500;

                    ${media.lessThan('400px')`
                        display: block;
                    `}
                }
            }

            .address{
                margin-top: 20px;
            }
        }

        ${media.lessThan('1100px')`
            width: 90%;
        `}

        ${media.lessThan('768px')`
            width: 100%;
            flex-direction: column;
        `}
    }

    ${media.lessThan('768px')`
        padding: 0 30px 100px 30px;
    `}
`

export const Questions = styled.div`
    width: 50%;
    margin: 0 auto 150px;

    h2{
        margin-bottom: 60px;
    }

    .question_answer{
        margin-bottom: 35px;
    }

    button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: #FBAF43;

        .arrow{
            position: relative;
            transition: all 0.3s ease-in-out;

            :after, :before{
                content: "";
                position: absolute;
                width: 2px;
                height: 12px;
                background: #fff;
                top: -7px;
                border-radius: 2px;
            }
        
            :after{
                transform: rotate(40deg);
                right: 2.5px;
            }
            
            :before{
                transform: rotate(-40deg);
                left: 2.5px;
            }
        }
    }

    .question{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;

        ${media.lessThan('768px')`
            font-size: 16px;
            
            .text{
                width: 92%;
                line-height: 1.5;
                margin-right: 15px;
            }
        `}
    }

    .answer{
        padding: 25px 0 20px;
        transition: all 0.4s ease-in-out;
        display: block;
        line-height:0;
        height: 0;
        overflow: hidden;
    }

    ${media.lessThan('1200px')`
        width: 60%;
    `}

    ${media.lessThan('1024px')`
        width: 85%;
    `}

    ${media.lessThan('768px')`
        width: auto;
        padding: 0 30px;
        margin: 0 auto 100px;

        .question_answer{
            margin-bottom: 20px;
        }
    `}
`
export const Contact = styled.div`
    margin: 0 auto 150px;
    padding: 70px 0;
    width: 70%;
    text-align: center;
    font-size: 20px;
    background: rgba(251, 175, 67, 0.20);
    display: flex;
    justify-content: center;
    align-items: center;

    p{
        width: 60%;
        line-height: 1.5;
    }

    .line{
        width: 90px;
        height: 2px;
        background: rgba(251, 175, 67, 1);
    }

    ${media.lessThan('1100px')`
        width: 80%;
    `}

    ${media.lessThan('900px')`
        flex-direction: column;
        margin: 0 30px 100px;
        width: auto;

        p{
            width: 90%;
            margin: 25px auto;
        }
    `}
`
//Modal Window

export const ModalWindow = styled.div`
   background: #fff;
   padding: 50px;
   width: 30%;
   border-radius: 0.5rem;
   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: relative;

   h3{
        color: rgba(0, 0, 0, 0.7);
        margin: 0 auto 30px;
        text-align: center;
        font-size: 26px;
        font-family: 'Roboto Condensed', sans-serif;
   }

   input, select{
        color: rgba(0, 0, 0, 0.7);
        font-size: 16px !important;
        border: 1px solid rgb(145, 145, 145) !important;
        border-radius: 0.5rem !important;
        padding: 10px 15px;
        margin-bottom: 15px;
        font-family: "Roboto Condensed", sans-serif;
        width: -webkit-fill-available !important;

        ::placeholder{
            color: rgba(0,0,0,0.7) !important; 
            font-family: 'Roboto Condensed', sans-serif;
            font-size: 16px;
        }
   }

   button{
        color: #fff;
        border-radius: 0.5rem;
        background: #FBAF43;
        width: 100%;
        padding: 12px 0;
        font-size: 16px;
        font-family: 'Roboto Condensed', sans-serif;
        letter-spacing: 0.8px;
        margin-top: 30px;
    }

   .react-tel-input{
        input{
            height: 100%;
        }
    }

    .flag-dropdown{
        border-radius: 0.5rem 0 0 0.5rem !important;
        border: 1px solid rgb(145, 145, 145) !important;
    }
    
    .message{
        color: rgba(0, 0, 0, 0.8); 
        text-align: center;
        font-size: 26px;
        font-weight: 600;
        line-height: 1.5;

        span{
            font-size: 16px;
            color: rgba(0, 0, 0, 0.7); 
            font-weight: 100;
        }
    }

    ${media.lessThan('1050px')`
        width: 40%;
        padding: 30px;
    `}

    ${media.lessThan('768px')`
        width: 100%;
        margin: 0 30px;
    `}
`

export const QuestionsAnswers = styled.div`
   
    margin-bottom: 35px;
    

    button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #FBAF43;

        .arrow{
            position: relative;
            transition: all 0.3s ease-in-out;

            :after, :before{
                content: "";
                position: absolute;
                width: 2px;
                height: 12px;
                background: #fff;
                top: -7px;
                border-radius: 2px;
            }
        
            :after{
                transform: rotate(40deg);
                right: 2.5px;
            }
            
            :before{
                transform: rotate(-40deg);
                left: 2.5px;
            }
        }
    }

    .question{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
    }

    .answer{
        padding: 25px 0 20px;
        transition: all 0.4s ease-in-out;
        display: block;
        line-height:0;
        height: 0;
        overflow: hidden;
    }
`
