// import img1 from "../../images/blog_images/1.webp"
// import img2 from "../../images/blog_images/2.webp"
// import img3 from "../../images/blog_images/3.webp"
// import img4 from "../../images/blog_images/4.webp"
// import img5 from "../../images/blog_images/5.webp"
// import img6 from "../../images/blog_images/6.webp"
// import img7 from "../../images/blog_images/7.webp"
// import img8 from "../../images/blog_images/8.webp"
// import img9 from "../../images/blog_images/9.webp"
// import img10 from "../../images/blog_images/10.webp"
// import img11 from "../../images/blog_images/11.webp"
// import img12 from "../../images/blog_images/12.webp"
// import img13 from "../../images/blog_images/13.webp"
// import img14 from "../../images/blog_images/14.webp"
// import img15 from "../../images/blog_images/15.jpg"
// import img16 from "../../images/blog_images/16.jpg"
// import img17 from "../../images/blog_images/17.webp"
// import img18 from "../../images/blog_images/18.webp"
// import img19 from "../../images/blog_images/19.webp"

export const blogData = [
  {
    name: "Как вернуть деньги от налога в Польше?",
    date: "11.11.2021",
    id: 20,
    img: "https://i.ibb.co/h1p0Pv3/blog.webp",
    desc: "Каждый иностранец может вернуть себе деньги от налога в Польше, заполнив декларацию PIT-37, но при условии ...",
    text: [
      "Каждый иностранец может вернуть себе деньги от налога в Польше, заполнив декларацию PIT-37, но при условии, что Вы были трудоустроены официально, так как в этом случае, Вы получаете от работодателя декларацию PIT-11, на основе которой рассчитывается PIT-37.",
      "PIT-11 - это форма с данными о годовых доходах и уплаченных налогах работника. Работодатель обязан отправить PIT-11 всем работникам до конца февраля в следующем году после отработанного года (то есть до конца февраля 2022 года Вы получите PIT-11 за отработанный 2021).",
      "Для того, чтобы рассчитать PIT-37 советуем Вам воспользоваться специальными сервисами, например e-pity.pl или podatki.gov.pl/pit/twoj-e-pit/. После расчета этими сервисами суммы возвращаемого налога Вам нужно будет просто сохранить PDF файл (который следует хранить в течении 5 лет) с декларацией и отправить его онлайн, предварительно выбрав Ваш Urząd Skarbowy (налоговая администрация). Это необходимо сделать до конца апреля следующего года, то есть PIT-37 за 2021 год должна быть подана до 30 апреля 2022 года. Соответственно налог возвращается в текущем году за все отработанные дни у всех работодателей в прошедшем году.",
      "Если у Вас открыт счет в польском банке, то налог возвращается на банковскую карту, иначе сумма налога может быть возвращена денежным переводом в почтовое отделение по Вашему адресу проживания в Польше."
    ],
  },
  {
    name: "Удержание заработной платы",
    date: "11.09.2021",
    id: 19,
    img: "https://i.ibb.co/FVT2fch/19.webp",
    desc: "Если Вы хотите уволиться или сменить работу, Вам необходимо написать заявление об увольнении, а далее отработать ...",
    text: [
      "Если Вы хотите уволиться или сменить работу, Вам необходимо написать заявление об увольнении, а далее отработать 14 календарных дней. При невыполнении этих условий работодатель вправе удержать зарплату в полном размере.",
      "В случае увольнения за нарушение трудовой дисциплины(воровство, нарушение порядка на рабочем месте, распитие алкогольных напитков и т.д.) работодатель также вправе удержать Вашу зарплату в полном размере.",
      "Избежать удержания заработной платы можно в случае, если Ваше увольнение связано с непредвиденными обстоятельствами, но в этом случае такая причина увольнения должна быть подтверждена (например если у Вас умер родственник, то необходимо предоставить свидетельство о смерти)."
    ],
  },
  {
    name: "Tarcza antykryzysowa",
    date: "07.09.2021",
    id: 18,
    img: "https://i.ibb.co/5Fg9Xnj/18.webp",
    desc: "Tarcza antykryzysowa (“Антикризисный щит”) — это специальные решения для иностранцев позволяющие продлить работу ...",
    text: [
      "Tarcza antykryzysowa (“Антикризисный щит”) — это специальные решения для иностранцев позволяющие продлить работу и пребывание в Польше во время эпидемии коронавируса.",
      "В случае ликвидации эпидемической угрозы или положения эпидемии в Польше продление на 30 дней будет включать:"
    ],
    listItems: [
      "легальное пребывание иностранцев с любыми типами виз,",
      "разрешение на временное проживание,",
      "сроки подачи заявок на легализацию пребывания,",
      "разрешения на работу и сезонные разрешения на работу,",
      "сроки выезда иностранцев с территории Польши"
    ],
    text2: [
      "То есть, если начиная с 14 марта 2020 года (введение эпидемиологической угрозы) Вы легально находились на территории Польши, но у Вас закончился срок действия визы, вида на жительство или закончился безвизовый режим, то Вы можете легально остаться в Польше в течение 30 дней после отмены чрезвычайного положения. Те же правила касаются разрешений на работу и сроков выезда с территории РП. Очень важно, чтобы истечение срока законного пребывания в Польше произошло в период введения эпидемического статуса, но не в течение 30 дней после его отмены."
    ]
  },
  {
    name: "Счет в банке",
    date: "05.09.2021",
    id: 17,
    img: "https://i.ibb.co/XsDYL2f/17.webp",
    desc: "Все наши сотрудники получают зарплату на банковский счёт. Наличный расчет невозможен. Поэтому по приезду в Польшу Вам необходимо ...",
    text: [
      "Все наши сотрудники получают зарплату на банковский счёт. Наличный расчет невозможен. Поэтому по приезду в Польшу Вам необходимо открыть счет в банке. Некоторые банки позволяют сделать это онлайн, но лучше всего и быстрее подойти в отделение банка где сотрудники помогут вам с процедурой открытия счета. На сегодняшний день большинство консультантов говорят на Вашем языке.",
      "При себе нужно иметь:"
    ],
    listItems: [
      "документ удостоверяющий личность",
      "номер PESEL",
      "oświadczenie или zezwolenie",
      "трудовой договор"
    ],
    text2: [
      "! Возможно два последних документа вам не понадобятся - это зависит от банка. Но лучше иметь их при себе. Информацию о необходимых документах, а также информацию об условиях пользования картой, оплаты и т.д. Вы можете уточнить на сайте банка (чаще всего его можно перевести на русский или украинский язык) либо же можете обратиться в справочную службу к русскоязычному консультанту.",
      "Деньги с карты можно снять в любом банкомате Польши. Но следует взять во внимание, что некоторые банки могут взимать комиссию если Вы снимаете деньги в банкомате банка клиентом которого не являетесь. Так же стоит обратить внимание на то, есть ли оплата за пользование картой и при каких условиях можно пользоваться картой бесплатно.",
      "Список банков, в которых можно открыть счёт:"
    ],
    listItems2: [
      "Millennium Bank",
      "PKO BP",
      "ING Bank Śląski",
      "Santander",
      "PEKAO",
      "Getin Bank",
      "mBank",
      "Alior Bank",
      "BNP Paribas",
      "Credit Agricole"
    ],
  },
  {
    name: "Воеводское приглашение",
    date: "02.09.2021",
    id: 16,
    img: "https://i.ibb.co/xDmD8Bs/16.jpg",
    desc: "Zezwolenie типа А (Воеводское приглашение) - это разрешение на работу, на основании которого иностранец из любой страны может ...",
    text: [
      "Zezwolenie типа А  - это разрешение на работу, на основании которого иностранец из любой страны может получить рабочую визу(иначе воеводская виза, виза типа D06) на срок до 1 года. Главным преимуществом этой визы является ее срок пребывания - 360 дней без необходимости выезда в течение года, то есть эта виза не имеет “коридоров”.",
      "Наше агентство занимается подготовкой воеводского приглашения, но при условии, что Вы ранее уже работали от нашей фирмы, то есть таким образом Вы можете продлить свой срок пребывания в Польше.",
      "Для получения приглашения типа А Вам необходимо выслать нам электронной почтой скан/фото всех страниц паспорта, далее внести оплату в размере 300 злотых и дальше уже ждать пока приглашение будет готово. Срок ожидания составляет от 45 до 60 дней.",
      "После того, как Вы получите Zezwolenie можете подавать документы на визу в Консульство или Визовый центр."
    ],
  },
  {
    name: "Karta pobytu",
    date: "29.08.2021",
    id: 15,
    img: "https://i.ibb.co/bb53RFG/15.jpg",
    desc: "Карта побыту (Karta pobytu) или иначе вид на жительство (временный или постоянный) – это документ позволяющий ...",
    text: [
      "Карта побыту (Karta pobytu) или иначе вид на жительство (временный или постоянный) – это документ позволяющий многократно пересекать границы Польши без получения дополнительных виз, а также легально находится в РП в указанный на карте период, на определенных законодательством основаниях.",
      "В этой статье разговор пойдет о карте временного побыту, которая выдается на основании разрешения на временное пребывание, на срок от 1 года до 3 лет.",
      "Для получения такого документа необходимо предоставить веские, документально подтверждены основания для пребывания на территории РП. Самое распространенное - это официальная работа на территории Польши.",
      "Подавать документы на карту побыту нужно лично в отдел по вопросам иностранцев (urząd wojewódzki) по месту прописки (в особых случаях, когда личное присутствие невозможно, документы может подать другой человек по доверенности, но в течение одной-двух недель Воевода может вызвать этого человека для снятия отпечатков пальцев).",
      "За рассмотрение заявления на карту временного побыту нужно оплатить гербовый сбор – 340 злотых. Кроме этого дополнительно нужно будет заплатить 50 злотых за изготовление пластиковой карточки.",
      "В среднем срок рассмотрения документов составляет около 3 месяцев, но в связи с тем, что во время рассмотрения будет происходить проверка данных заявителя польскими службами (полицией, пограничниками, агентством внутренней безопасности и т.д) процесс может растянуться и до года.",
      "В случае, когда срок действия визы закончился, а процесс изготовления карты еще продолжается, в ваш загранпаспорт поставят штамп (так называемая процессуальная виза), который позволит легально находится на территории Польши до вынесения решения по карте, но стоит помнить что при выезде с Польши данный штамп не позволяет повторно въезжать на территорию РП, в этом случае Вам необходима виза.",
      "Какие документы требуются для подачи на карту?"
    ],
    listItems: [
      "анкета заполненная на польском языке (Wniosek o udzielenie zezwolenia na pobyt czasowy)",
      "документ, подтверждающий необходимость пребывания в Польше (подтверждение из учебного заведения, трудовой договор и др.)",
      "загранпаспорт и копии всех страниц с отметками",
      "договор аренды жилья и прописка",
      "4 актуальные фото (3,5 х 4,5 см)",
      "медицинская страховка или подтверждение покрытия средств работодателем любых медицинских расходов на территории Польши",
      "квитанцию об оплате гербового сбора",
      "подтверждение наличия минимально необходимого финансового обеспечения"
    ],
  },
  {
    name: "Как вернуть деньги от налога в Польше?",
    date: "24.08.2021",
    id: 14,
    img: "https://i.ibb.co/k8Np4Rz/14.webp",
    desc: "Каждый иностранец может вернуть себе деньги от налога в Польше, заполнив декларацию PIT-37, но при условии ...",
    text: [
      "Каждый иностранец может вернуть себе деньги от налога в Польше, заполнив декларацию PIT-37, но при условии, что Вы были трудоустроены официально, так как в этом случае, Вы получаете от работодателя декларацию PIT-11, на основе которой рассчитывается PIT-37.",
      "PIT-11 - это форма с данными о годовых доходах и уплаченных налогах работника. Люди, работающие в разных местах в течение года, получают PIT-11 от каждого работодателя. Работодатель обязан отправить PIT-11 всем работникам до конца февраля в следующем году после отработанного года (то есть до конца февраля 2022 года Вы получите PIT-11 за отработанный 2021).",
      "Для того, чтобы рассчитать PIT-37 советуем Вам воспользоваться специальными сервисами, например e-pity.pl или podatki.gov.pl/pit/twoj-e-pit/. После расчета этими сервисами суммы возвращаемого налога Вам нужно будет просто сохранить PDF файл (который следует хранить в течении 5 лет) с декларацией и отправить его онлайн, предварительно выбрав Ваш Urząd Skarbowy (налоговая администрация). Это необходимо сделать до конца апреля следующего года, то есть PIT-37 за 2021 год должна быть подана до 30 апреля 2022 года. Соответственно налог возвращается в текущем году за все отработанные дни у всех работодателей в прошедшем году.",
      "Если у Вас открыт счет в польском банке, то налог возвращается на банковскую карту, иначе сумма налога может быть возвращена денежным переводом в почтовое отделение по Вашему адресу проживания в Польше."
    ],
  },
  {
    name: "Brutto/Netto",
    date: "19.08.2021",
    id: 13,
    img: "https://i.ibb.co/h1GCBTw/13.webp",
    desc: "Мы всегда в описании вакансий указываем часовую ставку в netto. Но существует такое понятие как Brutto ...",
    text: [
      "Мы всегда в описании вакансий указываем часовую ставку в netto. Но существует такое понятие как Brutto, именно в этой величине указана ваша зарплата в договоре и, как Вы можете заметить, она значительно выше чем та которую вы получаете на практике. Сейчас мы разберемся чем же отличаются эти 2 понятия.",
      "Зарплата брутто – это заработная плата работника вместе с подоходным налогом и со всеми отчислениями, которые нужно будет платить в различные фонды. Это ваша зарплата без вычета налогов.",
      "Зарплата нетто – это сумма, которую работник получает на руки.",
      "Для того, чтобы рассчитать зарплату в нетто советуем воспользоваться специальными онлайн калькуляторами, просто впишите в поисковике kalkulator wynagrodzeń"
    ],
  },
  {
    name: "BHP",
    date: "15.08.2021",
    id: 12,
    img: "https://i.ibb.co/wMnMDS4/12.webp",
    desc: "Каждый из наших сотрудников должен ознакомится с правилами BHP( Bezpieczeństwo i Higiena Pracy)...",
    text: [
      "Каждый из наших сотрудников должен ознакомится с правилами BHP( Bezpieczeństwo i Higiena Pracy). Давайте же разберемся что это такое и для чего это нужно.",
      "BHP или иначе правила техники безопасности и гигиены труда - это свод правил, которые описывают способы создания для сотрудников таких условий труда, которые были бы безопасными и гигиеничными. Эти правила позволяют сотрудникам ознакомится с методами безопасной работы, профессиональными рисками, факторами окружающей среды на рабочем месте, мерами защиты от угроз и т.д.",
      "Несоблюдение правил BHP может иметь серьезные последствия: смерть, проблемы со здоровьем, частичная или полная нетрудоспособность, изменение привычного образа жизни, финансовые проблемы и др. Сотрудник, не соблюдающий правила BHP, может быть подвергнут предупреждению, выговору, штрафу и даже увольнению. Следует помнить, что несоблюдение BHP представляет угрозу не только для самого работника, но и для других.",
      "С другой стороны, работодатель, который не выполняет свои обязанности в области правил техники безопасности и гигиены труда, подвергает себя штрафу, тюремному заключению на срок до 3 лет и даже запрету деятельности предприятия.",
      "Обучение правилам BHP должно проводится раз в полгода, а также каждый раз при смене места работы."
    ],
  },
  {
    name: "Зарплата",
    date: "07.08.2021",
    id: 11,
    img: "https://i.ibb.co/cbFcJq9/11.webp",
    desc: "У работников часто возникает вопрос в каких числах они получат зарплату.В соответствии с договором ...",
    text: [
      "У работников часто возникает вопрос в каких числах они получат зарплату.",
      "В соответствии с договором подряда (Umowa Zlecenie), на основе которого трудоустроены наши работники,  заработная плата выплачивается с 10 по 15 число следующего месяца после которого Вы начали работать.",
      "Кроме этого, после двух отработанных недель у Вас есть возможность получить единоразовый аванс. Сумма аванса оговаривается индивидуально.",
      "Следует помнить, что деньги поступают на счет, как правило, в течении 24 часов."
    ],
  },
  {
    name: "Преимущества работы в Польше",
    date: "05.08.2021",
    id: 10,
    img: "https://i.ibb.co/rcFfQgg/10.webp",
    text: [
      "Работа в Польше дает ряд весомых и бесспорных преимуществ для граждан стран Восточной Европы:"
    ],
    desc: "Работа в Польше дает ряд весомых и бесспорных преимуществ для граждан стран Восточной Европы ...",
    listItems: [
      "Высокая, стабильная заработная плата. Средняя з/п в Польше - 5457,00 	PLN (1477,61 USD)",
      "Условия труда полностью соответствующие европейским нормам",
      "Уровень социальной защиты в Польше является одним из самых высоких в ЕС",
      "Есть возможность получения бесплатной медицинской помощи при официальном трудоустройстве",
      "Знание языка и наличие опыта работы не является обязательным условием для многих популярных вакансий",
      "Простой способ трудоустройства",
      "Возможность постоянного проживания в Польше на легальной основе",
      "Заинтересованность польских работодателей работниками из стран Восточной Европы",
      "Сходство некоторых языков, в частности украинского и белорусского, с польским, а также сходство культур",
      "Отличное транспортное сообщение с Польшей"
    ]
  },
  {
    name: "Почему работники до 26 лет получают более высокую зарплату?",
    date: "02.08.2021",
    id: 9,
    img: "https://i.ibb.co/Sv1LzZs/9.webp",
    desc: "В Польше молодые люди в возрасте до 26 лет освобождены от уплаты налога на доходы физических лиц (PIT) ...",
    text: [
      "В Польше молодые люди в возрасте до 26 лет освобождены от уплаты налога на доходы физических лиц (PIT), поэтому они получают более высокую зарплату. Кроме возрастного фактора существует еще один: сумма годового дохода не должна превышать 85 528 злотых.",
      "Сумма налога PIT зависит от размера вашей заработной платы. Если Вы, например, получаете 3000 zł/netto то сумма налога PIT составит 243 zł, если же Ваша зарплата - 4000 zł/netto, то PIT составит 357 zł.  Для того, что рассчитать зарплату без налога PIT советуем Вам воспользоваться специальными онлайн калькуляторами (kalkulator wynagrodzeń или kalkulator podatkowy).",
      "Молодые люди до 26 лет, которые достигли дохода, превышающего 85 528 злотых в год, должны подать декларацию PIT в налоговую инспекцию (urząd skarbowy) по месту жительства и уплатить PIT, в остальных случаях подавать декларацию не нужно. Если молодой человек работал на нескольких работодателей, он должен суммировать полученный доход от всех работодателей.",
      "Заработная плата без налога PIT действительна до достижения работником 26-летнего возраста. Если деньги переводятся на счет до 26-го дня рождения или в день его рождения, налоговые льготы все еще применяются. Соответственно, в ситуации, когда вознаграждение переводится на счет на следующий день после достижения 26-летнего возраста, право на освобождение от налога теряется.",
      "Следует помнить, что освобождение от уплаты налога PIT не означает освобождение от уплаты обязательных взносов в Национальный фонд здравоохранения(NFZ) и Учреждение социального страхования(ZUS)."
    ]
  },
  {
    name: "Как проверить Oświadczenie powierzenia pracy",
    date: "28.07.2021",
    id: 8,
    img: "https://i.ibb.co/fGt3DmJ/8.webp",
    desc: "Чтобы быть уверенным в положительном исходе запроса на рабочую визу, необходимо убедиться в правильности ...",
    text: [
      "Чтобы быть уверенным в положительном исходе запроса на рабочую визу, необходимо убедиться в правильности оформления Oświadczenia powierzenia pracy.",
      "Прежде всего, Вам следует самостоятельно проверить документ на правильность: все функциональные поля должны быть заполнены и размещены в правильном порядке, Ваши данные должны быть указаны в точном соответствии с паспортными, с обратной стороны должна стоять электронная подпись и должны быть указаны даты с биржи труда, документ также должен содержать данные о работодателе.",
      "Кроме этого Вы можете связаться с Центром Занятости (Urząd Pracy) с просьбой проверить подлинность документа, лучше всего сделать это с помощью электронной почты.",
      "В документе должны быть указаны контакты работодателя – позвонив по номеру, Вы можете получить подтверждение, что именно эта фирма (лицо), указанная в документе, предоставила Вам данное разрешение.",
      "Ко всему прочему, Вы можете проверить работает ли QR-код расположенный на лицевой стороне документа отсканировав его с помощью смартфона."
    ]
  },
  {
    name: "Для чего нужно официальное трудоустройство?",
    date: "27.07.2021",
    id: 7,
    img: "https://i.ibb.co/WKJn0Zn/7.webp",
    desc: "При подписании трудового договора работодатель регистрирует работника в ZUS (управление социального ...",
    listItems: [
      "При подписании трудового договора работодатель регистрирует работника в ZUS (управление социального страхования), что позволяет получить бесплатную медицинскую помощь при необходимости.",
      "Если Вы планируете остаться в Польше на длительный срок то официальное трудоустройство упростит процедуру легализации пребывания.",
      "К тому же, если Вы официально трудоустроены и планируете остаться в Польше навсегда, то это значит что Вы уже работаете на будущую пенсию.",
      "Раз в год Вы можете подать налоговую декларацию PIT-11 с целью возврата уплаченных за год налогов."
    ]
  },
  {
    name: "Алгоритм переезда в Польшу",
    date: "25.07.2021",
    id: 6,
    img: "https://i.ibb.co/ZWLvkFs/1.webp",
    desc: "Сперва нужно ознакомится с перечнем доступных вакансий и выбрать подходящую именно Вам, далее ...",
    listItems: [
      "Сперва нужно ознакомится с перечнем доступных вакансий (c актуальными вакансиями можно ознакомится по ссылке) и выбрать подходящую именно Вам.",
      "Для работы необходимо получить разрешение на работу (Oświadczenie powierzenia pracy), Вам для этого надо будет только отправить скан первой страницы паспорта и внести оплату в размере 30 злотых, а дальше уже наши сотрудники будут заниматься подготовкой данного документа, Вам остается лишь ждать пока он будет готов. Срок ожидания около 7 дней.",
      "После того, как разрешение будет готово - Вам его вышлют электронной почтой, далее Вы его распечатываете и со всеми необходимыми документами идете в визовый центр или консульство для подачи заявки на визу. Если Вы являетесь гражданином/гражданкой страны, в которой действуют биометрические паспорта и планируете работать в Польше на основании биометрии, то виза не требуется.",
      "Далее Вы связываетесь с нами, мы уточняем дату Вашего приезда, после чего Вы выбираете удобный для вас способ добраться в Варшаву.",
      "С автобусного вокзала Варшава-Западная (Dworzec Zachodni) Вас встречает наш партнер оказывающий транспортные услуги, и отвозит на место прохождения карантина (если в Вашем случае такая необходимость существует). Карантин наши работники проходят в Łomiankach (ок. 15 км от Варшавы).",
      "После карантина Вы приезжаете к нам в офис для того, чтобы подписать все необходимые документы. Офис находится в Варшаве по адресу Pelplińska 8b.",
      "После этого Вы добираетесь до места работы либо самостоятельно, либо с нашим партнёром (стоимость поездки зависит от расстояния и вычитается с зарплаты, можно также оплатить сразу).",
      "На месте работы с Вами будет проведен вводный инструктаж с переводчиком, для лучшего понимания Ваших обязанностей, а дальше уже можете приступать к работе."
    ]
  },
  {
    name: "Карантин",
    date: "22.07.2021",
    id: 5,
    img: "https://i.ibb.co/2vFLfK6/2.webp",
    desc: "В Польше существует обязанность прохождения 10-дневного карантина для лиц прибывающих из стран ...",
    text: [
      "В Польше существует обязанность прохождения 10-дневного карантина для лиц, прибывающих в Польшу из стран, не входящих в шенгенскую зону.",
      "Избежать прохождение карантина могут люди, которые полностью вакцинированы против коронавируса (должно пройти 14 дней после получения последней дозы вакцины), а также те кто был заражен (и выздоровел) не позднее чем за 6 месяцев до дня пересечения границы Польши. И в том, и в другом случае при пересечении границы необходимо будет предъявить соответствующие документы. Кроме этого карантин может быть отменен спустя семь дней в случае получения негативного теста на коронавирус.",
      "Если все же Вам необходимо пройти карантин, Вы можете не переживать по этому поводу, так как наше агентство предоставляет для этих целей специальное жилье. Оно является бесплатным в случае, если Вы планируете работать в Польше больше двух месяцев, если нет, то необходимо внести оплату в размере 400 злотых.",
      "Кроме этого наше агентство организовывает транспорт до места прохождения карантина, то есть по приезду в Варшаву, с вокзала Варшава-Западная (Dworzec Zachodni), Вас забирает наш партнёр и отвозит в г. Łomianki (ок. 15 км от Варшавы) где находится жилье. Услуги водителя стоят 40-60 злотых.",
      "Дом оснащен всем необходимым для комфортного проживания, в комнате проживают от 2 до 6 людей, в основном по 4 человека."
    ]
  },
  {
    name: "Oświadczenie powierzenia pracy",
    date: "19.07.2021",
    id: 4,
    img: "https://i.ibb.co/xLjpRXh/3.webp",
    desc: "Для легальной работы в Польше требуется разрешение на работу: Oświadczenie или Zezwolenie типа А ...",
    text: [
      "Для легальной работы в Польше гражданам 6 стран: Украина, Молдова, Грузия, Беларусь, Россия, Армения требуется разрешение на работу: Oświadczenie(так называемая простая форма разрешения на работу) или Zezwolenie типа А(иначе воевудское приглашение). Всем остальным странам, кроме выше перечисленных, требуется исключительно Zezwolenie типа А. Рассмотрим сперва что же такое Oświadczenie.",
      "На основании этого документа иностранцы вышеперечисленных стран могут подать документы на получение полугодовой или годовой рабочей визы со сроком пребывания до 180 дней. А если Вы являетесь гражданином/гражданкой страны в которой действуют биометрические паспорта, то Вы можете пересечь границу не открывая визы, пребывать и работать на территории Польши до 90 дней.",
      "За выдачей этого документа обращается работодатель, а выдаёт его Центр Занятости (Urząd Pracy). Вам лишь надо отправить скан первой страницы паспорта и если Вы находитесь в Польше, то скан визы и печать въезда. Обычно срок  выдачи документа составляет около 7 дней, а стоимость составляет 30 злотых - эта сумма взимается Центром Занятости и только эту сумму Вам нужно будет оплатить сотрудничая с нашим агентством.",
      "Важно понимать, что если Вам открывают визу на год, то работать и находиться в Польше Вы можете не более 180 дней, считая от дня пересечения границы. В Вашем Oświadczeniu дни работы пересекаются с коридорами, в период которых Вы не можете работать.",
      "Если работник пожелает сменить работодателя новый работодатель обязан оформить новое заявление и на основе него заключить договор. Срок нового Oświadczenia уменьшится с полугода на то количество дней которое вы уже отработали."
    ]
  },
  {
    name: "номер PESEL",
    date: "17.07.2021",
    id: 3,
    img: "https://i.ibb.co/nQ0B5YG/4.webp",
    desc: "Каждый работник сотрудничающий с нашим агентством получает номер PESEL, давайте же разберемся что это ...",
    text: [
      "Каждый работник сотрудничающий с нашим агентством получает номер PESEL, давайте же разберемся что это такое и для чего он нужен.",
      "Номер PESEL — это цифровой код, используемый для идентификации физических лиц. Этот идентификационный номер состоит из одиннадцати цифр и включает: дату рождения, серийный номер, пол и контрольный номер.",
      "Для чего он нужен?",
      "Номер PESEL может потребоваться в самых различных ситуациях – например, при взаимодействии с органами управления, для получения медицинской помощи, для страхования, открытия банковского счета и других банковских услуг, для получения образования в автошколах, на курсах, в учебных заведениях и т.д.",
      "Как получить иностранцу PESEL?",
      "Иностранцы, оформившие прописку в Польше на срок более 30 дней, получают номер PESEL автоматически. Если же возможности прописаться нет следует подать заявление в соответствующее муниципальное управление (urząd gminy). Сделать это можно самостоятельно или через доверенное лицо. Сотрудничая с нашим агентством Вам просто нужно будет подписать доверенность и предоставить оригинал паспорта,  далее всеми делами касающимися получения номера Pesel занимается наш отдел легализации. Срок получения от 7 до 14 дней.",
      "Получить PESEL можно совершенно бесплатно!"
    ],
  },
  {
    name: "Система штрафов",
    date: "15.07.2021",
    id: 2,
    img: "https://i.ibb.co/wR0bY7X/5.webp",
    desc: "Трудоустроенный нашим агентством работник имеет право на 2 дополнительных дня отсутствия, кроме тех ...",
    text: [
      "Трудоустроенный нашим агентством работник имеет право на 2 дополнительных дня отсутствия, кроме тех выходных дней которые предусмотрены графиком работы.",
      "За каждое следующее отсутствие на рабочем месте работник должен будет оплатить штраф в размере 100 злотых, который будет вычтен с его зарплаты. Но избежать выплаты штрафа можно если Ваше отсутствие связано с проблемами со здоровьем, в этом случае Вы должны предоставить справку от врача. Помните о том, что все наши работники застрахованы, что позволяет Вам при необходимости получить бесплатную медицинскую помощь.",
      "Кроме этого, следует взять во внимание, что если Вы не вернете выданную Вам рабочую одежду и инструменты, то их стоимость будет также вычтена из Вашей зарплаты."
    ],
  },
  {
    name: "Umowa Zlecenie",
    date: "09.07.2021",
    id: 1,
    img: "https://i.ibb.co/PtQFhXJ/6.webp",
    desc: "На основе Oświadczenia работодатель может заключать с вами такие типы трудовых договоров как: Umowa o pracę, Umowa zlecenie ...",
    text: [
      "На основе Oświadczenia работодатель может заключать с вами такие типы трудовых договоров как: Umowa o pracę, Umowa zlecenie и Umowa o dzieło. Наши сотрудники работают только на основе Umowy Zlecenia поэтому мы подробнее расскажем именно про этот тип  договора.",
      "Umowa zlecenie (договор подряда) – это гражданско-правовой вид договора, то есть главные особенности описаны и регулируются в гражданском кодексе РП (Kodeks Cywilny), а не трудовом (Kodeks Pracy).",
      "Особенности договора:"
    ],
    listItems: [
      "Гибкий график работы",
      "Простота в заключении и расторжении (помните о том, что, сотрудничая с нашим агентством, при расторжении договора Вам необходимо отработать 14 календарных дней от момента написания заявления об увольнении)",
      "Этот договор является более выгодным по налогообложению, так как подразумевает сокращенный перечень социальных выплат, что позволяет получать выше ставку нетто",
      "При подписании договора работодатель регистрирует работника в ZUS (управление социального страхования), что позволяет получить бесплатную медицинскую помощь при необходимости",
      "Возможны премии",
      "Нет ограничений в количестве рабочих часов"
    ]
  },
];