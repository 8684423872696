import React, { useState, useEffect } from "react"
import { ArticleIntro, ReadAlso, InfoBlock, Text } from "./styles.js"
import { Link, useParams } from 'react-router-dom'
import Slider from "react-slick"
import { SectionTitle } from '../titles/index.js'
import calendar from '../../images/calendar.svg'
import { blogData } from "./data.js"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const ArticlePage = () => {
    const [article, setArticle] = useState(null)
    const [list, setList] = useState([])
    const [text, setText] = useState([])
    const [allPosts, setAllPosts] = useState([])
    const [posts, setPosts] = useState([])

    const params = useParams()
    const blogId = params.id

    useEffect(() => {
        blogData.forEach(element => {
            if (element.id == blogId) {
                setArticle(element)
                setList(element.listItems)
                setText(element.text)
                return
            }
        })
    })

    useEffect(() => {
        // setAllPosts(blogData.sort(() => Math.random() - 0.5))
        setPosts(blogData.slice(1, 8))
    }, [allPosts])

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
        ]
    };

    return (
        article == null ? <div /> :
            <>
                <ArticleIntro style={{ background: `url(${article.img})` }}>
                    <h1 className='title'>{article.name}</h1>
                    <p className='date'><img src={calendar} alt='img' /> {article.date}</p>
                </ArticleIntro>
                <Text>
                    <div className='links'>
                        <Link to='/'>Главная</Link>
                        <span />
                        <Link to='/blog'>Блог</Link>
                        <span />
                        <Link to='/article'>{article.name}</Link>
                    </div>
                    {text == null ? <div /> :
                        <ul>
                            {text.map((data, key) => {
                                return (
                                    <p>{data}</p>
                                )
                            })}
                        </ul>
                    }
                    {list == null ? <div /> :
                        <ul>
                            {list.map((data, key) => {
                                return (
                                    <li>{data}</li>
                                )
                            })}
                        </ul>
                    }
                    {article.text2 == null ? <div /> :
                        <ul>
                            {article.text2.map((data, key) => {
                                return (
                                    <p>{data}</p>
                                )
                            })}
                        </ul>
                    }
                    {article.listItems2 == null ? <div /> :
                        <ul>
                            {article.listItems2.map((data, key) => {
                                return (
                                    <li>{data}</li>
                                )
                            })}
                        </ul>
                    }
                </Text>
                <ReadAlso>
                    <SectionTitle>Читайте также</SectionTitle>
                    <Slider {...settings}>
                        {posts.map((data, key) => {
                            let link = "/article/" + data.id
                            return (
                                <Link to={link}>
                                    <InfoBlock className='article'>
                                        <div className='img_block'>
                                            <img src={data.img} alt='img' />
                                            <span className='date'>{data.date}</span>
                                        </div>
                                        <h3>{data.name}</h3>
                                    </InfoBlock>
                                </Link>
                            )
                        })}
                    </Slider>
                </ReadAlso>
            </>
    )
}

export default ArticlePage