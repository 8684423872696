import React, { useState } from "react"
import { Stars } from "./styles.js"
import Star from './star.js'

const StarRating = ({ value, selection, setSelection, setValue, setIsMessageShown }) => {
  const [rating, setRating] = React.useState(parseInt(value) || 0)

  const hoverOver = event => {
    let val = 0;
    if (event && event.target && event.target.getAttribute('data-star-id'))
      val = event.target.getAttribute('data-star-id');
    setSelection(val);
  }

  function showRating(e) {
    setRating(e.target.getAttribute('data-star-id') || rating)
    setValue(selection)
    setIsMessageShown(true)
  }

  return (
    <Stars
      onMouseOut={() => hoverOver(null)}
      onClick={e => showRating(e)}
      onMouseOver={hoverOver}
    >
      {Array.from({ length: 5 }, (v, i) => (
        <Star
          starId={i + 1}
          key={`star_${i + 1}`}
          marked={selection ? selection >= i + 1 : rating >= i + 1}
        />
      ))}
    </Stars>
  );
};

export default StarRating