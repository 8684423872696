import React, { useState, useRef } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from '../header/header.js'
import Main from '../main/main.js'
import Blog from '../blog/blogPage.js'
import ArticlePage from '../blog/article.js'
import Vacancies from '../vacancies/vacancies.js'
import VacancyPage from '../vacancyPage/vacancyPage.js'
import About from '../aboutPage/about.js'
import Admin from '../admin/admin.js'
import LegalizationPage from '../legalizationPage/legalizationPage.js'
import Contact from '../contactUs/contactUs.js'
import Footer from '../footer/footer.js'
import MobileMenu from "../mobileMenu/mobileMenu.js"
import { Container, CloseMobileMenu } from './styles.js'
import ScrollToTop from './scroll.js'
import FeedBack from '../feedbacks/feedbackPage.js'
import Modal from '../modal/modal.js'

const Routes = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)

    function openModal() {
        setIsModalOpen(true)
        document.body.style.overflowY = "clip"
    }

    const myRef = useRef(null)

    return (
        <Router>
            <Container>
                <ScrollToTop setIsMenuOpen={setIsMenuOpen}/>
                <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
                <MobileMenu isMenuOpen={isMenuOpen} />
                <CloseMobileMenu onClick={() => setIsMenuOpen(false)} style={{width: isMenuOpen ? `100%` : `0px`}}/>
                <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
                <Switch>
                    <Route exact path="/">
                        <Main openModal={openModal}/>
                    </Route>
                    <Route exact path="/about">
                        <About myRef={myRef} openModal={openModal}/>
                    </Route>
                    <Route exact path="/blog">
                        <Blog />
                    </Route>
                    <Route path="/article/:id">
                        <ArticlePage />
                    </Route>
                    <Route exact path="/vacancies">
                        <Vacancies openModal={openModal}/>
                    </Route>
                    <Route exact path="/vacancy/:id">
                        <VacancyPage openModal={openModal}/>
                    </Route>
                    <Route exact path="/feedbacks">
                        <FeedBack />
                    </Route>
                    <Route exact path="/legalization">
                        <LegalizationPage />
                    </Route>
                    <Route exact path="/admin">
                        <Admin />
                    </Route>
                </Switch>
                <Contact myRef={myRef}/>
                <Footer />
            </Container>
        </Router>
    )
}

export default Routes