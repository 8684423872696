import React, { useState, useEffect } from "react"
import { Container, Logo } from "./styles.js"
import LogoImg from '../../images/logo_main.png'
import { ArrowBtn } from "../buttons/buttons.js"
import Slide from 'react-reveal/Slide'

const Intro = ({executeScroll, myRef}) => {
  
  return (
    <Slide top>
    <Container>
      <Logo src={LogoImg} alt='Formika' />
      <ArrowBtn onClick={() => executeScroll(myRef)}><div className="arrow" /></ArrowBtn>
    </Container>
    </Slide>
  )
}

export default Intro