import React from "react"
import { Container, Social } from './styles.js'
import facebook from '../../images/facebook.svg'
import instagram from '../../images/instagram.svg'
import viber from '../../images/viber.svg'

const Footer = () => {
    return (
        <Container>
            <div>Copyright © Formika {new Date().getFullYear()}</div>
            <Social>
                <a href="https://www.facebook.com/F-O-R-M-I-K-A-%D0%A2%D1%80%D1%83%D0%B4%D0%BE%D1%83%D1%81%D1%82%D1%80%D0%BE%D0%B9%D1%81%D1%82%D0%B2%D0%BE-%D0%B2-%D0%9F%D0%BE%D0%BB%D1%8C%D1%88%D0%B5-%D0%B8-%D0%95%D0%A1-361672737585574" target='_blank'><img src={facebook} alt='facebook' /></a>
                <a href="https://www.instagram.com/formika_praca/" target='_blank'><img src={instagram} alt='instagram' /></a>
                <a href=""><img src={viber} alt='viber' /></a>
            </Social>
        </Container>
    )
}

export default Footer