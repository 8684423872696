import React, { useState, useEffect } from "react"
import { ModalWindow, CloseButton } from "./styles.js"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Modal = ({ isModalOpen, setIsModalOpen }) => {
    const [name, setName] = useState()
    const [tel, setTel] = useState()
    const [telViber, setTelViber] = useState()
    const [time, setTime] = useState()
    const [isErrorName, setIsErrorName] = useState(false)
    const [isErrorTel, setIsErrorTel] = useState(false)
    const [isFormSend, setIsFormSend] = useState(false)

    function closeModal() {
        setIsModalOpen(false)
        document.body.style.overflowY = "scroll"
        setIsFormSend(false)
    }

    function setInputName(e) {
        setName(e.target.value)
        if (name !== undefined && name !== null) {
            setIsErrorName(false)
        }
    }

    function setInputTel(formattedValue) {
        setTel(formattedValue)
        if (tel !== undefined && tel !== null) {
            setIsErrorTel(false)
        }
    }

    function validateForm() {
        if (name !== undefined && tel !== undefined && name !== null && tel !== null) {
            sendMessage()
        } else if (name == undefined || tel == null) {
            setIsErrorName(!isErrorName)
            setIsErrorTel(!isErrorTel)
        }
    }

    async function sendMessage() {
        var url = 'https://crm.formika-praca.pl/form/api?'
        var params = { imie: name, nazwisko: "", telefon: tel, viber: telViber, termin_kontaktu: time, source: "formikapraca.eu" }
        var requestUrl = 'https://crm.formika-praca.pl/form/api?' + new URLSearchParams(params).toString()
        
        let formData = new FormData();
        formData.append('imie', name);
        formData.append('nazwisko','');
        formData.append('telefon', tel);
        formData.append('viber', telViber);
        formData.append('termin_kontaktu', time);
        formData.append('source', 'formikapraca.eu');
        
        await fetch('https://crm.formika-praca.pl/form/api?', {
            method: 'POST',
            // headers: {
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json'
            // },
            body: formData
        })
            .then((response) => {
                setIsFormSend(true)
            })
    }

    // let link = `https://api.telegram.org/bot1926026633:AAGilrMsntVe8OQWejB1znBXC-KtQ_KBzPk/sendMessage?chat_id=-1001557024724&text=<b>Name: </b> ${name}<b> Phone: </b>${tel} <b> Viber: </b> ${telViber}<b> Time: </b> ${time}&parse_mode=html`

    // async function sendMessage() {
    //     await fetch(link)
    //         .then((response) => {
    //             setIsFormSend(true)
    //         })
    // }

    return (
        <ModalWindow style={{ display: isModalOpen ? `flex` : `none` }}>
            <div className='form' style={{ height: isFormSend ? `50%` : `auto` }}>
                {isFormSend ? <p className='message'>Ваш запрос отправлен!</p> :
                    <>
                        <p className='modal_title'>Оставьте свой номер телефона и мы Вам перезвоним.</p>
                        <p className='modal_text'>Наш менеджер поможет подобрать для Вас вакансию, а также ответит на все Ваши вопросы</p>
                        <div className='form_wrapper'>
                            <input placeholder='Имя' name="Имя" required onChange={e => setInputName(e)} />
                            <p style={{ display: isErrorName ? `block` : `none` }}>Поле обязательное для заполнения</p>
                            <PhoneInput
                                country={'ua'}
                                onlyCountries={['ua', 'by', 'pl', 'md', 'ge', 'az', 'ro', 'am', 'uz', 'tj', 'kz', 'kg']}
                                onChange={setInputTel}
                                required
                            />
                            <p style={{ display: isErrorTel ? `block` : `none` }}>Поле обязательное для заполнения</p>
                            <PhoneInput
                                onlyCountries={['ua', 'by', 'pl', 'md', 'ge', 'ro', 'am', 'uz', 'tj', 'kz', 'kg', 'az']}
                                onChange={formattedValue => setTelViber(formattedValue)}
                                placeholder='Viber'
                            />
                            <select name="time" id="time" onChange={e => setTime(e.target.value)}>
                                <option value="not selected">Выберете удобное время для связи</option>
                                <option value="с 9:00 до 13:00">с 9:00 до 13:00</option>
                                <option value="с 13:00 до 18:00">с 13:00 до 18:00</option>
                                <option value="после 18:00">после 18:00</option>
                            </select>
                            <button onClick={validateForm}>Отправить</button>
                        </div>
                    </>
                }
                <CloseButton className='close-btn' onClick={closeModal} />
            </div>
        </ModalWindow>
    )
}

export default Modal